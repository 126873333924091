import React, { useState, useEffect } from "react";
import SideMenu from "../Components/sideMenu";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup"
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Swal from "sweetalert2";

import { Search } from 'react-bootstrap-icons';
import { getAxios, deleteAxios, isEmpty, clearSaveData } from "../Components/commonFunctions";

const TaskManager=(props)=>{
    const [getSearchText, setSearchText] = useState("");
    const [getType, setType] = useState("assigned");

    const [getListtasks, setListTasks] = useState([]);
    const [getOwnedtasks, setOwnedtasks] = useState([]);
    const [getAssignedTasks, setAssignedTasks] = useState([]);
    const [getRevisionTasks, setRevisionTasks] = useState([]);
    const [getRejectedTasks, setRejectedTasks] = useState([]);
    const [getApprovedTasks, setApprovedTasks] = useState([]);

    useEffect(()=>{
        clearSaveData();
        getTasks();
        // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        searchTask();
        // eslint-disable-next-line
    },[getSearchText]);

    useEffect(()=>{
        updateTasks();
        searchTask();
        // eslint-disable-next-line
    },[getType]);
    
    const showStatusList = () => {
        if(localStorage.getItem("level") !== "3"){
            return(<></>);
        }
        else{
            return(
                <>
                    <Form.Select onChange={(e) => setType(e.target.value)} value={getType} aria-label="Default select example">
                        <option value="assigned">Asignados</option>
                        <option value="owned">Creados</option>
                        <option value="revision">Revisión</option>
                        <option value="approved">Aprobados</option>
                        <option value="rejected">Rechazados</option>
                    </Form.Select>
                </>
            );
        }
    }
    const getTasks = async() =>{
        await getAxios("task/get").then(Response => {
            setListTasks(removeSubmitedTasks(Response.assigned));
            setOwnedtasks(Response.owned);
            setAssignedTasks(Response.assigned);
        });
        await getAxios("task/getStatus?status=submitted").then(response => {
            setRevisionTasks(response);
        });
        await getAxios("task/getStatus?status=rejected").then(response => {
            setRejectedTasks(response);
        });
        await getAxios("task/getStatus?status=approved").then(response => {
            setApprovedTasks(response);
        });
    }
    const updateTasks =() =>{
        switch(getType){
            case "assigned":
                setListTasks(removeSubmitedTasks(getAssignedTasks));
                break;
            case "owned":
                setListTasks(getOwnedtasks);
                break;
            case "revision":
                setListTasks(getRevisionTasks);
                break;
            case "approved":
                setListTasks(getApprovedTasks);
                break;
            case "rejected":
                setListTasks(getRejectedTasks);
                break;
            default:
                break;
        }
    }
    const removeSubmitedTasks = (elements) =>{
        return elements.filter(item => !(item.status === "submitted" ));
    }
    const removeSelectedTask = (tid) =>{
        let newList = [];
        switch(getType){
            case "assigned":
                newList = getAssignedTasks.filter(item => !(item.tid === tid ));;
                setAssignedTasks(newList);
                break;
            case "owned":
                newList = getOwnedtasks.filter(item => !(item.tid === tid ));
                setOwnedtasks(newList);
                break;
            case "revision":
                newList = getRevisionTasks.filter(item => !(item.tid === tid ));
                setRevisionTasks(newList);
                break;
            case "approved":
                newList = getApprovedTasks.filter(item => !(item.tid === tid ));
                setApprovedTasks(newList);
                break;
            case "rejected":
                newList = getRejectedTasks.filter(item => !(item.tid === tid ));
                setRejectedTasks(newList);
                break;
            default:
                break;
        }
        setListTasks(newList);
    }
    const openTask = (e, tid, version, title, status, assigned) =>{
        if (e.target.id === "buttonDelete"){
            return;
        }
        else{
            localStorage.setItem("tid", tid);
            localStorage.setItem("taskVersion", version);
            localStorage.setItem("taskName", title);
            localStorage.setItem("taskStatus", status);
            localStorage.setItem("taskAssigned", assigned);
            window.location.href = "/WorkingTask";
        }
    }
    const deleteFunction = async(tid) => {
        Swal.fire({
            title: "¿Estas seguro que quieres borrar esta tarea?",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText : "No",
          }).then((result) => {
            if (result.isConfirmed) {
                let json = {tid:tid}
                deleteAxios("task/delete?tid=" + tid, JSON.stringify(json)).then(response => {                 
                    removeSelectedTask(tid);
                });
            }
          });
    }
    const showDeleteButton = (tid, status) => {
        let level = localStorage.getItem("level");
        if(level === "3" && status !== "submitted" && getType !== "revision"){
            return(
                <Button id="buttonDelete" onClick={() => deleteFunction(tid)} className="delete-task" variant="danger">Borrar</Button>
            );
        }
        return(
            <></>
        );
    }
    const createTaskItem = (tid, title, status, created, assignee, modified, progress, version) =>{
        return(
            <div key={tid} onClick={(e) => {openTask(e, tid, version, title, status, assignee)}}>
                <Card>
                    <Card.Header className="container-fluid">
                        <Row>
                            <Col sm={8} md={8} lg={10}><h5>{title}</h5></Col>
                            <Col sm={4} md={4} lg={2}><h5>Status: </h5><p>{status}</p></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="container-fluid">
                        <Row>
                            <Col sm={10} md={10}>
                                <ButtonGroup ><h5>Creado el: </h5><p>{created}</p></ButtonGroup >
                                <ButtonGroup ><h5>Asignado a: </h5><p>{assignee}</p></ButtonGroup >
                                <ButtonGroup ><h5>Última Modificación: </h5><p>{modified}</p></ButtonGroup >
                            </Col>
                            <Col sm={2} md={2}>
                                {showDeleteButton(tid)}
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-muted"><ProgressBar now={progress} label={"progress: " + progress + "%"}/></Card.Footer>
                </Card>
                <br/>
            </div>
        );
    }
    const searchTask = () =>{
        if(isEmpty(getSearchText)){
            updateTasks();
        }
        else{
            let list = [];
            for (let index = 0; index < getListtasks.length; index++) {
                let information = "*," + Object.values(getListtasks[index]).join("*,").toLowerCase();
                if(information.includes("*,"  + getSearchText)){
                    list.push(getListtasks[index]);
                }
            }
            setListTasks(list);
        }
    }
    return(
        <>
        <SideMenu title="Administrador de tareas"/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="#">Administrador de tareas</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={10} lg={10}>
                    <InputGroup className="mb-3">
                        <Form.Control onChange={(e) => setSearchText(e.target.value)} placeholder="Buscar tarea" aria-label="Buscar tarea" aria-describedby="basic-addon2"/>
                        <Button variant="outline-secondary" id="button-addon2"><Search/></Button>
                    </InputGroup>
                </Col>
                <Col sm={12} md={2} lg={2}>
                    {showStatusList()}
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                <React.Fragment>
                {
                    getListtasks.map( ({tid, title, created, version, status, owner, assignee, modified, progress}, index) => {
                        return createTaskItem( tid, title, status, created, assignee, modified, progress, version );
                    })
                }
                </React.Fragment>
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default TaskManager;