import React, { useState, useEffect } from "react";
import SideMenu from "../Components/sideMenu";
// import { HotTable } from "@handsontable/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2"
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
// import { SendFill, TrashFill } from 'react-bootstrap-icons';
import { getAxios, postAxios, putFetchToken, deleteAxios_2 } from "../Components/commonFunctions";
// import { countValidator14, countValidator20, countValidator64, countValidator100, countValidator128 } from "../Components/validators"
// import InputGroup from 'react-bootstrap/InputGroup';
// import { TextRenderer, NumericRenderer, AutocompleteRenderer, CheckboxRenderer, LimitValueRenderer } from "../Components/renderers";
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
 
const UserManager=(props)=>{
    const levelOptions = [
        {label:"Usuario normal", value:1},
        {label:"Manager", value:2},
        {label:"Administrador", value:3}
    ];
    const statusOptions = [
        {label:"Activo", value:1},
        {label:"Inactivo", value:0}
    ];
    const [getUsers, setUsers] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [getNames, setNames] = useState("");    
    const [getLastName, setLastNames] = useState("");
    const [getEmail, setEmail] = useState("");
    const [getPass, setPass] = useState("");
    const [getCheckPass, setCheckPass] = useState("");
    const [getPhone, setPhone] = useState("");
    const [getMobile, setMobile] = useState("");
    const [getLevel, setLevel] = useState(levelOptions[0]);
    const [getStatus, setStatus] = useState(statusOptions[0]);
    const [getuid, setuid] = useState("");

    const [getMode, setMode] = useState("");

    const [getShowPass, setShowPass] = useState(false);

    useEffect(()=>{
        if(localStorage.getItem("level") !== "3"){
            window.location.href = "/taskManager";
        }
        _getUsers();
        // eslint-disable-next-line
    },[]);
    
    const _getUsers = async() => {
        await getAxios("user/getData").then(response => {
            if(response.success === true){              
                setUsers(response.data);
            }
        });
    }

    const addUser = () => {
        setMode("add")     
        setNames("");
        setLastNames("");
        setEmail("");
        setPass("");
        setPhone("");
        setMobile("");
        setLevel(levelOptions[0]);
        setStatus(statusOptions[0]);

        handleShow();
    }
    const editUser = (index) => {      
        setMode("edit")
        const user = getUsers[index];
        setuid(user.uid);
        setNames(user.name);
        setLastNames(user.lastname);
        setEmail(user.email);
        setPass("");
        setPhone(user.phone);
        setMobile(user.mobile);
        setLevel(levelOptions[user.level-1]);
        setStatus(user.active === 1 ? statusOptions[0] : statusOptions[1]);
        handleShow();
    }
    const validateData = () => {
        let result = true;
        const invalid = [null, undefined, "null", "undefined"];
        

        if(getMode === "edit"){
            const values = [getNames, getLastName, getEmail, getLevel, getStatus, getPass, getPhone, getMobile];
            for (let index = 0; index < values.length; index++) {
                if(invalid.includes(values[index])){
                    result = false;
                }
                if(index < 5 && values[index] === ""){
                    result = false;
                }
                if(getPass !== ""){
                    if(getPass.length < 8){
                        result = false;
                    }
                    if(getPass !==  getCheckPass){
                        result = false;
                    }
                }            
            }
        }
        else{
            const values = [getNames, getLastName, getEmail, getLevel, getStatus, getPass, getPhone, getMobile];
            for (let index = 0; index < values.length; index++) {
                if(invalid.includes(values[index])){
                    result = false;
                }
                if(index < 6 && values[index] === ""){
                    result = false;
                }
                if(getPass.length < 8){
                    result = false;
                }
                if(getPass !==  getCheckPass){
                    result = false;
                }
            }
        }
        return result;
    }
    const sendData = async() => {  
        if(!validateData()){
            Swal.fire({
                icon: "warning",
                title: "Espera!",
                text: "hay información incompleta",
            });
        }
        else{
            const json = {
                "name": getNames,
                "lastname": getLastName,
                "email": getEmail,
                "phone": getPhone,
                "mobile": getMobile,
                "level": getLevel.value
            }
            if(getMode === "add"){
                json["pass"] = getPass;
                await postAxios("user/", JSON.stringify(json)).then(response => {
                    _getUsers();
                    handleClose();
                });
            }
            else if (getMode === "edit"){
                json["uid"] = getuid;
                if(getPass !== ""){
                    json["pass"] = getPass;
                }
                json["active"] = getStatus.value;
                await putFetchToken("user/", JSON.stringify(json)).then(response => {
                    _getUsers();
                    handleClose();
                });
            }
        }
        
    }
    const deleteUser = (uid) =>{
        Swal.fire({
            icon: "warning",
            title: "Espera!",
            text: "¿Esta seguro de eliminar este usuario?",
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: "Cancelar"
        }).then(async(result) => {
            const json = {"uid":uid};
            await deleteAxios_2("user/", JSON.stringify(json)).then(response => {
                if (response.success){
                    _getUsers();
                }
            })
        })
    }
    const userRow = (index, uid, user, name, lastname, email, phone, mobile, active, level) =>{
        const desactiveStyle = {backgroundColor:"#6c757d", color:"white", verticalAlign: "middle"};
        const activeStyle = {backgroundColor:"#198754", color:"white", verticalAlign: "middle"};
        return(
            <tr key={index} style={active === 1 ? activeStyle : desactiveStyle}>
                <td>{`${name} ${lastname}`}</td>
                <td>{email}</td>
                <td>{ levelOptions[level-1].label }</td>
                <td>{ active === 1 ? "Activo" : "Inactivo" }</td>
                <td style={{textAlignLast: "right"}}>
                    {
                        active === 1 ? <><Button variant="dark" onClick={()=>{deleteUser(uid)}}> Deshabilitar</Button>{" "}</> : <></>
                    } 
                    <Button variant="dark" onClick={()=>{editUser(index)}}> Editar</Button>
                                       
                </td>
            </tr>
        )
    }
    return(
        <>
        <SideMenu title="Usuarios"/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="/taskManager">Administrador de Usuarios</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ButtonToolbar className="justify-content-end">
                        <ButtonGroup>
                            <Button variant="danger" onClick={()=>{addUser()}}> Agregar usuario </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive="sm">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Email</th>
                                <th>nivel</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {getUsers.map(({uid, user, name, lastname, email, phone, mobile, active, level}, index)=>{
                                return userRow(index, uid, user, name, lastname, email, phone, mobile, active, level);
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Agregar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Label>Nombres:<span style={{color:"red"}}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="" value={getNames} onChange={(e)=>{setNames(e.target.value)}} />
                    </Col>
                    <Col>
                        <Form.Label>Apellidos:<span style={{color:"red"}}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="" value={getLastName} onChange={(e)=>{setLastNames(e.target.value)}} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                    <Form autocomplete="off">
                        <Form.Label>Email:<span style={{color:"red"}}>*</span></Form.Label>
                        <Form.Control autoComplete="off" disabled={getMode==="edit"} type="email" placeholder="name@example.com" value={getEmail} onChange={(e)=>{setEmail(e.target.value)}} />
                        <br/>
                        <Form.Label >Contraseña:<span style={{color:"red"}}>*</span></Form.Label>
                        <Form.Control autocomplete="new-password" type={getShowPass ? "text" : "password"} value={getPass} onChange={(e)=>{setPass(e.target.value)}} />
                        <br/>
                        <Form.Label >Confirmar contraseña:<span style={{color:"red"}}>*</span></Form.Label>
                        <Form.Control autocomplete="new-password" type={getShowPass ? "text" : "password"} value={getCheckPass} onChange={(e)=>{setCheckPass(e.target.value)}} />
                        <Form.Check type="switch" label="Mostrar contraseña" onClick={()=>{setShowPass(!getShowPass)}}/>
                        <Form.Text id="passwordHelpBlock-" muted>
                            La contraseña tiene que ser de al menos 8 caracteres.
                        </Form.Text>
                    </Form>
                        
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Form.Label>Telefono:</Form.Label>
                        <Form.Control type="text" placeholder="" value={getPhone} onChange={(e)=>{setPhone(e.target.value)}}/>
                    </Col>
                    <Col>
                        <Form.Label>Movil:</Form.Label>
                        <Form.Control type="text" placeholder="" value={getMobile} onChange={(e)=>{setMobile(e.target.value)}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Nivel:<span style={{color:"red"}}>*</span></Form.Label>
                        <Select className="overTable" onChange={(e) => setLevel(e)} value={getLevel} options={levelOptions}></Select>
                    </Col>
                    <Col>
                    {
                        getMode === "edit" ? <>
                            <Form.Label>Status:<span style={{color:"red"}}>*</span></Form.Label>
                            <Select className="overTable" onChange={(e) => setStatus(e)} value={getStatus} options={statusOptions}></Select>
                        </> : <></>
                    }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Cancelar </Button>
                <Button variant="danger" onClick={()=>(sendData())}> { getMode === "edit"? "Actualizar" : "Crear"} </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
 }

export default UserManager;