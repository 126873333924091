import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {CloudArrowUp, FiletypeTxt } from 'react-bootstrap-icons';

const DropFileInput = (props) =>{

    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    useEffect(()=>{
        setFileList([]);
        // eslint-disable-next-line
    },[props.clearData]);

    const onFileDrop = (e) => {
        const newFiles = e.target.files;
        if (newFiles) {
            let updatedList = [...fileList];
            for (let index = 0; index < newFiles.length; index++) {
                if(newFiles[index].type.split('/')[1] === "plain"){
                    updatedList = [...updatedList, newFiles[index]];
                }
            }
            setFileList(updatedList);
            props.onFileChange(updatedList);
        }
    }
    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }
    return(
        <>
        <div ref={wrapperRef} className="drop-file-input" onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                <div className="drop-file-input__label">
                    <CloudArrowUp size={100}/>
                    <p>Arrastra un TXT (Delimitado por TABS)</p>
                </div>
                <input type="file" accept=".txt" value="" onChange={onFileDrop}/>
            </div>
            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">
                            Listo para actualizar:
                        </p>
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <FiletypeTxt size={100} className='drop-icon'/>
                                    <div className="drop-file-preview__item__info">
                                        <p>{item.name}</p>
                                        <p>{item.size + " "}Bytes</p>
                                    </div>
                                    <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
        </>
    );
}

DropFileInput.prototypes = {
    onFileChange: PropTypes.func
}

export default DropFileInput;