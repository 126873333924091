import React, { useState, useEffect, useRef } from "react";
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import SideMenu from "../Components/sideMenu";
import Swal from "sweetalert2";
import { HotTable } from "@handsontable/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { BookmarkCheckFill, BookmarkXFill, SendCheckFill, SaveFill, FileEarmarkArrowDownFill, FileEarmarkArrowUpFill, LayoutSplit, Search, CaretLeftFill, CaretRightFill, FileEarmarkRichtextFill, Images, CollectionFill } from "react-bootstrap-icons";
import { substituteHeaders, getAxios, postAxios, isEmpty, clearSaveData, columnOrder, returnDefault0, formulaAmazon, formulaDynamic, formulaWalmart, formulaPackageContent } from "../Components/commonFunctions";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Stack from "react-bootstrap/Stack";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TagsEditor from "../Components/TagsEditor";
import { tagsRenderer, HiperLinksRenderer } from "../Components/renderers";
import { regexValidator, countValidator2, countValidator4, countValidator5, countValidator6, countValidator12, countValidator32, countValidator60_num, countValidator200_num } from "../Components/validators";
import ButtonColor from "../Components/ColorPicker";

const WorkingTask=(props)=>{
    const emptySelect = {value:"",label:"Vacío"};
    const readOnlyColumns = [
        "itemNumber", 
        "upc",
        "ean",
        "asin",
        "dept",
        "class",
        "subclass",
        "brand",
        "description",
        "color_brand",
        "package_content",
        "short_description_character_count",
        "dynamic_description_character_count",
        "mercado_libre_preview_character_count"
    ];

    let searchIndexSelection = 0;
    let row_ = null;
    let col_ = null;
    let coincidences = [];

    let swich = false;
    let getSearchField = "";
    let getClassSelected = emptySelect.value;
    let getSubClassSelected = emptySelect.value;
    let getBrandSelected = emptySelect.value;
    let getDeptSelected = emptySelect.value;

    let colorValue = "";
    window.cellsColorsList = [];

    const [getColors, setColors] = useState([]);
    const [getSubstitutes, setSubstitutes] = useState([]);

    const [getClassOptions, setClassOptions] = useState([]);
    const [getSubClassOptions, setSubClassOptions] = useState([]);
    const [getDeptOptions, setDeptOptions] = useState([]);
    const [getBrandOptions, setBrandOptions] = useState([]);

    const [getTableValues, setTableValues] = useState([]);
    const [getHeaders, setHeaders] = useState([]);
    const [getColumns, setColumns] = useState([]);

    const textArea = React.createRef();
    const handsonTable = useRef(null);

    const handsonTableSettings = {
        licenseKey:"non-commercial-and-evaluation",
        data: getTableValues,
        colHeaders:getHeaders,
        columns:getColumns,
        columnSorting: true,
        colWidths: [
            100,100,100,100,100,100,100,100,100,250,
            250,100,100,250,250,250,250,250,250,250,
            250,250,250,250,250,250,250,250,250,250,
            250,250,250,250,250,250,250,250,250,250,
            250,250,250,250,250,250,150,150,150,150,
            150,150,150,150,150,150,150,150,150,150,
            150,150,150,150,150,150,150,150,150,150,
            150,150,150,150,150,150,150,150,150,150,
            150,150,150,150,150,150,150
        ],
        manualRowResize: true,
        manualColumnResize: true,
        wordWrap: false,
        width:"100%",
        height:"50vh",
        outsideClickDeselects: false,
        beforeKeyDown: (event) => {
            if(event.target.closest('.controls, .bp3-dialog, .bp3-popover, .bp3-overlay-backdrop, .bp3-fill, .searchField')){
                event.stopImmediatePropagation()
            }
        },
        afterSelectionEnd:function(row, column, row2, column2, preventScrolling, selectionLayerLevel){
            if(row < 0 || column < 0){ return; }
            row_ = row;
            col_ = column;
            textArea.current.value = this.getDataAtCell(row, column);
            textArea.current.readOnly = readOnlyColumns.includes(substituteHeaders(this.getCellMeta(row, column).prop, "original"));
        },
        afterColumnSort:function(currentSortConfig, destinationSortConfigs){
            let colList = [];
            let newColorList = [];
            for (let index = 0; index < window.cellsColorsList.length; index++) {
                let col = window.cellsColorsList[index]["col"];
                if(!colList.includes(col)){
                    colList.push(col);
                }
            }
            for (let indexColumn = 0; indexColumn < colList.length; indexColumn++) {
                for (let indexRow = 0; indexRow < this.getData().length; indexRow++) {
                    let cellMeta = this.getCellMeta(indexRow, colList[indexColumn])["className"];
                    cellMeta = isEmpty(cellMeta) ? "" : cellMeta;
                    if(cellMeta !== "" && cellMeta.includes("color_")){
                        newColorList.push({ row:indexRow, col:colList[indexColumn], className:cellMeta })
                    }
                }
            }
            window.cellsColorsList = newColorList;
        },
        afterChange: function(changes, source){
            if(changes === null){ return; }
            for (let index = 0; index < changes.length; index++) {
                let row = changes[index][0];
                let prop = changes[index][1];

                let columnsToAmazon = ["brand", "name","model","product_type","description_features","color", "description_extended_features"];
                if(columnsToAmazon.includes(prop)){
                    let color = getColor(this.getDataAtRowProp(row, "color"));
                    color.then(value => {
                        let AmazonValue = formulaAmazon(this.getDataAtRowProp(row, "brand"), this.getDataAtRowProp(row, "model"), this.getDataAtRowProp(row, "product_type"), this.getDataAtRowProp(row, "description_features"), this.getDataAtRowProp(row, "description_extended_features"), value["color_es"], getSubstitutes);
                        this.setDataAtRowProp(row, "amazon_description_preview", AmazonValue);
                    });
                }

                let columnsToWalmartAndDynamic = ["brand", "model", "product_type", "product_name", "description_features" ];
                if(columnsToWalmartAndDynamic.includes(prop)){
                    let productName = this.getDataAtRowProp(row, "product_name");
                    let productType = this.getDataAtRowProp(row, "product_type");
                    let brand = this.getDataAtRowProp(row, "brand");
                    let descriptionFeatures = this.getDataAtRowProp(row, "description_features");
                    let modelName = this.getDataAtRowProp(row, "model");

                    let PackageContentValue = formulaPackageContent(productType, brand, modelName, getSubstitutes, productName);
                    this.setDataAtRowProp(row, "package_content", PackageContentValue);

                    let DynamicDescriptionValue = formulaDynamic(productName, productType, brand, descriptionFeatures, modelName, getSubstitutes);
                    this.setDataAtRowProp(row, "dynamic_description_preview", DynamicDescriptionValue);

                    let walmartDescriptionValue = formulaWalmart(productName, productType, brand, descriptionFeatures, modelName, getSubstitutes);
                    this.setDataAtRowProp(row, "walmart_description_preview", walmartDescriptionValue);
                }
                if(prop === "ml_manual_title"){
                    let count = this.getDataAtRowProp(row, prop).toString().length;
                    this.setDataAtRowProp(row, "mercado_libre_preview_character_count", count);
                }
                if(prop === "dynamic_description_preview"){
                    let count = this.getDataAtRowProp(row, prop).toString().length;
                    this.setDataAtRowProp(row, "dynamic_description_character_count", count);
                }
                if(prop === "name"){
                    let count = this.getDataAtRowProp(row, prop).toString().length;
                    this.setDataAtRowProp(row, "short_description_character_count", count);
                }
            }
        }
    };

    useEffect(()=>{
        clearSaveData();
        getData();
        // eslint-disable-next-line
    },[]);
    useEffect(()=>{
        handsonTable.current.hotInstance.validateCells();
        // eslint-disable-next-line
    },[handsonTable]);
    const getColor = (Color) =>{
        return postAxios("task/colorTranslate", JSON.stringify({"color" : Color}) );
    }
    const getData = async() =>{
        let colors = [];
        await getAxios("task/getColor?field=color").then(response => {
            setColors(response.colors);
            colors = response.colors;
        });
        await getAxios("product/getTypes").then(response => {
            setSubstitutes(response);
        });
        await getAxios("task/getDetailBuffer?tid=" + localStorage.getItem("tid") + "&version=" + localStorage.getItem("taskVersion")).then(response => {
            dataProcessing(response, colors);
        });
    }
    const dataProcessing = (data, colors) =>{
        // llenado de selects.
        let optionClass = [emptySelect];
        let optionSubClass = [emptySelect];
        let optionsDept = [emptySelect];
        let optionsBrand = [emptySelect];
        for (let index = 0; index < data.length; index++) {
            if(!optionClass.some(item => item.label === data[index].class)){
                optionClass.push({value:data[index].class, label:data[index].class});
            }
            if(!optionSubClass.some(item => item.label === data[index].subclass)){
                optionSubClass.push({value:data[index].subclass, label:data[index].subclass});
            }
            if(!optionsDept.some(item => item.label === data[index].dept)){
                optionsDept.push({value:data[index].dept, label:data[index].dept});
            }
            if(!optionsBrand.some(item => item.label === data[index].brand)){
                optionsBrand.push({value:data[index].brand, label:data[index].brand});
            }
            // sepacacion de features.
            let features = data[index].features;
            if(!isEmpty(features))
            for (let featuresIndex = 0; featuresIndex < 10; featuresIndex++) {
                data[index]["feature_" + (featuresIndex + 1).toString()] = isEmpty(features[featuresIndex]) ? "" : features[featuresIndex];
            }
            // eliminacion de campos no necesarios.
            let fieldsToRemove = ["tid","version","category","feature_tags","features"];
            for (let indexToRemove = 0; indexToRemove < fieldsToRemove.length; indexToRemove++) {
                delete data[index][fieldsToRemove[indexToRemove]];
            }
            data[index]["keywords"] = data[index]["keywords"].replaceAll(";",",");
        }
        
        setTableValues(data);
        setHeaders(createHeaders());
        setColumns(createColumns(colors));

        setClassOptions(optionClass);
        setSubClassOptions(optionSubClass);
        setDeptOptions(optionsDept);
        setBrandOptions(optionsBrand);

        getJsonColor();
    }
    const createHeaders = () =>{
        let headersFromData = columnOrder;
        let newHeaders = [];
        for (let index = 0; index < headersFromData.length; index++) {
            newHeaders.push(substituteHeaders(headersFromData[index]));
        }
        return newHeaders;
    }
    const createColumns = (colors) =>{
        const noValidateColumns = [
            "itemNumber", 
            "upc", 
            "ean", 
            "asin", 
            "dept", 
            "class", 
            "subclass", 
            "brand",
            "color_brand", 
            "description_features", 
            "dimensions_unit", 
            "weight_unit", 
            "warranty_unit", 
            "color", 
            "wired_data",
            "cloud_storage",
            "sensors", 
            "health_fitness_tracking",
            "device_additional_features",
            "protection_rating",
            "lithium_battery",
            "wireless",
            "lifetime_warranty",
            "voltage",
            "url",
            "video_url",
            "warranty_length",
            "length",
            "width",
            "height",
            "pkg_length",
            "pkg_width",
            "pkg_height",
            "weight",
            "pkg_weight",
            "package_content"
        ];
        const numericColumns = [
            "battery_capacity",
            "audio_power_output",
            "frequency_response_max",
            "frequency_response_min",
            "speaker_quantity"
        ];
        const tagsColumns = [
            {column:"input_ports", data:["","USB-A", "USB-C", "HDMI", "Optical Audio", "Conector de Audio de 3.5mm", "RCA", "XLR", "Ethernet"]},
            {column:"output_ports", data:["","USB-A", "USB-C", "HDMI", "Optical Audio", "Conector de Audio de 3.5mm", "RCA", "XLR", "Ethernet"]},
            {column:"sensors", data:["", "GPS","GLONASS","Galileo","QZSS","BeiDou","Brújula","Altímetro","Acelerómetro","Giroscopio","Sensor Eléctrico de Frecuencia Cardiaca","Sensor Óptico de Frecuencia Cardiaca","Oxímetro de Pulso","Sensor de Luz Ambiental","Termómetro"]},
            {column:"health_fitness_tracking", data:["","Conteo Pasos","Pisos Subidos","Calorías","Distancia","Estadísticas de Ejercicio","Alerta de Sedentarismo","Medición del Estrés","Monitor de Sueño","Salud de la Mujer"]},
            {column:"device_additional_features", data:["","Notificaciones de Celular","Alerta Vibratoria","Reproducción de Música","Almacenamiento de Música","Micrófono","Bocina","Llamadas en la Muñeca","No Molestar","Asistente de Voz","Reloj Mundial","Temporizador","Cronómetro","Alarma","Calendario","Listas","Clima","Linterna","Pagos sin Contacto","Control de Cámara","Encuentra mi Teléfono","Mapas"]},
            {column:"protection_rating", data:["","IP1X","IP2X","IP3X","IP4X","IP5X","IP6X","IPX1","IPX2","IPX3","IPX4","IPX5","IPX6","IPX7","IPX8","3atm/30m","5atm/50m","10atm/100m","20atm/200m"]}
        ];
        const dropDownColumns = [
            {column:"warranty_unit", data:["month", "year"]},
            {column:"color", data: colors},
            {column:"audio_channels", data:["1.0", "2.0", "2.1", "3.0", "3.1", "4.0", "4.1", "5.0", "5.1", "6.0", "6.1", "7.0", "7.1", "7.1.4", "8.0", "9.0", "9.1", "11.0", "11.1", "11.1.4"]},
            {column:"recording_resolution_max", data:["480p SD", "720p HD", "1080p Full HD", "4K"]},
            {column:"screen_protection_material", data:["Plástico", "Vidrio", "Cristal Templado", "Gorilla Glass DX+", "Gorilla Glass 3", "Gorilla Glass 3+", "Gorilla Glass 5", "Gorilla Glass 6", "Gorilla Glass Victus", "Ceramic Shield", "Cristal de Zafiro"]}
        ];

        let column2 = [
            "speaker_quantity"
        ];
        let column4 = [
            "audio_power_output"
        ];
        let column5 = [
            "battery_capacity", 
            "frequency_response_max", 
            "frequency_response_min"
        ];
        let column6 = [
            "audio_channels"
        ];
        let column12 = [
            "display_resolution"
        ];
        let column32 = [
            "wireless_connectivity", 
            "media_streaming_protocol", 
            "power_source",   
            "recording_resolution_max", 
            "screen_protection_material"
        ];

        let newColumns = [];
        for (let index = 0; index < columnOrder.length; index++) {
            let column = {data: columnOrder[index], readOnly: false};
            
            let tagsItem = tagsColumns.find(item => item.column === columnOrder[index]);
            let dropdownItem = dropDownColumns.find(item => item.column === columnOrder[index]);

            if(!isEmpty(tagsItem)){
                column.source = tagsItem.data.sort();
                column.strict = true;
                column.renderer = tagsRenderer;
                column.editor = TagsEditor;
            }

            if(!isEmpty(dropdownItem)){
                column.type = "dropdown";
                column.source = dropdownItem.data;
                column.strict= true;
                column.allowInvalid = false;
            }

            if(["url","video_url"].includes(columnOrder[index])){
                column.renderer = HiperLinksRenderer;
            }

            if(["wired_data", "cloud_storage"].includes(columnOrder[index])){
                column.type = "checkbox";
                column.allowInvalid = false;
                column.checkedTemplate = "1";
                column.uncheckedTemplate = "0";
            }

            if(readOnlyColumns.includes(columnOrder[index])){
                column.readOnly = true;
            }

            if(!noValidateColumns.includes(columnOrder[index]) && !numericColumns.includes(columnOrder[index])){
                column.validator = regexValidator;
                column.allowInvalid = true;
            }

            if(column2.includes(columnOrder[index])){
                column.validator = countValidator2;
            }
            if(column4.includes(columnOrder[index])){
                column.validator = countValidator4;
            }
            if(column5.includes(columnOrder[index])){
                column.validator = countValidator5;
            }
            if(column6.includes(columnOrder[index])){
                column.validator = countValidator6;
            }
            if(column12.includes(columnOrder[index])){
                column.validator = countValidator12;
            }
            if(column32.includes(columnOrder[index])){
                column.validator = countValidator32;
            }

            if(columnOrder[index] === "mercado_libre_preview_character_count" || columnOrder[index] === "dynamic_description_character_count"){
                column.validator = countValidator60_num;
            }
            if(columnOrder[index] === "short_description_character_count"){
                column.validator = countValidator200_num;
            }

            if(numericColumns.includes(columnOrder[index])){
                column.type = "numeric";
            }

            newColumns.push(column);
        }
        return newColumns;
    }
    const updateSelection = async(name, object) =>{
        switch (name) {
            case "class":
                getClassSelected = object.value;
                break;
            case "subclass":
                getSubClassSelected = object.value;
                break;
            case "dept":
                getDeptSelected = object.value;
                break;
            case "brand":
                getBrandSelected = object.value;
                break;
            default:
                break;
        }
    }
    const search = () =>{
        searchIndexSelection = 0;
        const instance = handsonTable.current.hotInstance;
        instance.deselectCell();
        const headers = instance.getColHeader();
        let data = instance.getData();
        let specialCharacters = swich ? "*," : "";

        let newData = [];
        for (let index = 0; index < data.length; index++) {
            let obj = {};
            let info = data[index];
            headers.forEach((element, i) => { obj[element] = info[i]; });
            newData.push(obj);
        }
        data = null;
        
        let rowsSelected = [];
        coincidences = [];
        for (let index = 0; index < newData.length; index++) {
            let stringToCompare = specialCharacters + Object.values(newData[index]).join(specialCharacters).toLowerCase();
            if(
                (newData[index]["Department"] === getDeptSelected || getDeptSelected === "") &&
                (newData[index]["Class"] === getClassSelected || getClassSelected === "") &&
                (newData[index]["Subclass"] === getSubClassSelected || getSubClassSelected === "") &&
                (newData[index]["Brand"] === getBrandSelected || getBrandSelected === "")
            ){
                if(getSearchField !== "" && stringToCompare.includes(specialCharacters + getSearchField)){
                    let information = Object.values(newData[index]);
                    for (let i = 0; i < information.length; i++) {
                        if( !isEmpty(information[i]) && (specialCharacters + information[i]).toLowerCase().includes(specialCharacters + getSearchField)){
                            coincidences.push({row:index, column: i});
                        }
                    }
                }
                rowsSelected.push([index,0, index, headers.length-1]);
            }
        }
        
        if(rowsSelected.length !== newData.length && coincidences.length === 0){
            instance.selectCells(rowsSelected);
        }

        if(coincidences.length > 0){
            instance.selectCell(coincidences[0].row, coincidences[0].column)
        }

        if(rowsSelected.length === newData.length && coincidences.length === 0){
            Swal.fire({icon: "info", title: "Sin coincidencias.", text: "No se encontro ningun producto con la informacion seleccionada", showConfirmButton: false, timer: 1500});
        }
    }
    const nextCoincidence = (value) =>{
        searchIndexSelection = searchIndexSelection + value;
        searchIndexSelection = searchIndexSelection < 0 ? coincidences.length-1 : searchIndexSelection;
        searchIndexSelection = searchIndexSelection > coincidences.length-1 ? 0 : searchIndexSelection;

        const instance = handsonTable.current.hotInstance;
        instance.deselectCell();
        instance.selectCell(coincidences[searchIndexSelection].row, coincidences[searchIndexSelection].column);
    }
    const openPreview = () =>{
        saveData(true).then(response=>{
            let instance = handsonTable.current.hotInstance;
            let selected = instance.getSelected();
            if(!isEmpty(selected)){
                let row = Math.min(selected[0][0], selected[0][2]) < 0 ? 0 : Math.min(selected[0][0], selected[0][2]);
                let headers = instance.getColHeader();
                let dataRow = instance.getDataAtRow(row);
                let object = {};
                for (let index = 0; index < headers.length; index++) {
                    object[substituteHeaders(headers[index], "original")] = dataRow[index];
                }
                localStorage.setItem( "selectedItem", JSON.stringify(object));
                localStorage.setItem( "originPage", "WorkingTask");
                localStorage.setItem( "originName", "Área de trabajo");
                window.location.href = "/productPreview";
            }
        });        
    }
    const openUploadPage = async(urlPage) =>{
        await saveData(true).then(response => {
            let instance = handsonTable.current.hotInstance;
            let selected = instance.getSelected();
            if(selected !== null && selected !== undefined){
                let row = Math.min(selected[0][0], selected[0][2]) < 0 ? 0 : Math.min(selected[0][0], selected[0][2]);
                
                let upc = instance.getDataAtRowProp(row, "upc");
                let itemNumber = instance.getDataAtRowProp(row, "itemNumber");
                let name = instance.getDataAtRowProp(row, "description");
    
                localStorage.setItem( "upc_selected", upc);
                localStorage.setItem( "itemNumber_selected", itemNumber);
                localStorage.setItem( "name_selected", name);
    
                window.location.href = urlPage;
            }
            else{
                Swal.fire({icon: "info", title: "Espera!", text: "Selecciona un producto antes.", showConfirmButton: false, timer: 1500});
            }
        });
    }
    const textAreaEnter = async(event) =>{
        var key = window.event.keyCode;
        if (key === 13) {
            event.persist();
            event.target.value = event.target.value.replace("\n"," ");
            handsonTable.current.hotInstance.setDataAtCell(row_, col_, event.target.value);
            event.target.blur();
        }
    }
    const isfocus = async(e) =>{
        e.persist();
        handsonTable.current.hotInstance.setDataAtCell(row_, col_, e.target.value);
    }
    const exportarCSV = () =>{
        let instance = handsonTable.current.hotInstance;
        let data = instance.getData();
        let headers = [];
        for (let index = 0; index < getHeaders.length; index++) {
            headers.push( substituteHeaders(getHeaders[index], "original") );
        }
        let csv = [headers.join(";")  + "\n"];
        for (let index = 0; index < data.length; index++){
            csv.push((data[index].join(";") + "\n"));
        }
        let date = new Date().toLocaleDateString("es-Mx").replaceAll("/", "-");
        saveAs(new Blob(csv,{type:"text/plain;charset=utf-8"}), localStorage.getItem("taskName") + " " + date + ".csv");
    }
    const exportarXLSX = () =>{
        let instance = handsonTable.current.hotInstance;
        let headers = [];
        for (let index = 0; index < getHeaders.length; index++) {
            headers.push( substituteHeaders(getHeaders[index], "original") );
        }
        let save = [headers, ...instance.getData()];
        const ws = XLSX.utils.aoa_to_sheet(save);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");
        var wbout = XLSX.write(wb, {bookType:"xlsx", bookSST:true, type:"binary"});
        let date = new Date().toLocaleDateString("es-Mx").replaceAll("/", "-");
        saveAs(new Blob( [s2ab(wbout)], {type:"application/octet-stream"}), localStorage.getItem("taskName") + " " + date + ".xlsx");
    }
    const s2ab = (s) =>{
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }
    const importFile = async() =>{
        const { value: file } = await Swal.fire({
            title: "Selecciona un archivo.",
            input: "file",
            inputAttributes: {
              "accept": ".csv, .xlsx",
              "aria-label": "Sube tu archivo."
            }
          })
          if (file) {
            if(file.name.split('.').pop() === "csv"){
                const reader = new FileReader();
                reader.onload = function (e) {
                    importCSV(e.target.result);
                };
                reader.readAsText(file);
            }
            else{
                importXLSX(file);
            }
          }
    }
    const importCSV = (information) =>{
        let data = [];
        let array = information.split("\n");
        let headers = array[0].split(";");
        for (let index = 1; index < array.length-1; index++) {
            let rowData = array[index].split(";");
            let item = {};
            for (let indexRow = 0; indexRow < rowData.length; indexRow++) {
                item[headers[indexRow]] = rowData[indexRow];
            }
            data.push(item);
        }
        dataProcessing(data, getColors);
    }
    const importXLSX = (file) =>{
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((d) => {
            let data = [];
            for (let rowIndex = 0; rowIndex < d.length; rowIndex++) {
                let row = {};
                let headers = Object.keys(d[rowIndex]);
                let values = Object.values(d[rowIndex]);
                for (let columnIndex = 0; columnIndex < headers.length; columnIndex++) {
                    row[substituteHeaders(headers[columnIndex],"original")] = values[columnIndex];
                }
                data.push(row);
            }
            dataProcessing(data, getColors);
        });
    }
    const colorseleccionado =(value) =>{
        colorValue = (value === "#FFFFFF") ? "" : "color_" + value.replace("#","");
        paint();
    }
    const paint = () =>{
        const instance = handsonTable.current.hotInstance;
        let selection = instance.getSelected();
        if(selection !== null){
            for (let index = 0; index < selection.length; index += 1) {
                let item = selection[index];
                let startRow = Math.min(item[0], item[2]) < 0 ? 0 : Math.min(item[0], item[2]);
                let endRow = Math.max(item[0], item[2]) < 0 ? 0: Math.max(item[0], item[2]);
                let startCol = Math.min(item[1], item[3]) < 0 ? 0 : Math.min(item[1], item[3]);
                let endCol = Math.max(item[1], item[3]) < 0 ? 0 : Math.max(item[1], item[3]);

                for (let rowIndex = startRow; rowIndex <= endRow; rowIndex += 1) {
                    for (let columnIndex = startCol; columnIndex <= endCol; columnIndex += 1) {
                        instance.setCellMeta(rowIndex, columnIndex, "className", colorValue);
                        let find = window.cellsColorsList.findIndex(x => x.row === rowIndex && x.col === columnIndex)
                        if(find === -1){
                            window.cellsColorsList.push({ row:rowIndex, col:columnIndex, className: colorValue})
                        }
                    }
                }
            }
            instance.render();
        }
    }
    const getSort = () =>{
        return handsonTable.current.hotInstance.getPlugin('columnSorting').getSortConfig();
    }
    const setSortConfig =(sortConfig) =>{
        const instance = handsonTable.current.hotInstance;
        instance.getPlugin("columnSorting").sort(sortConfig);
    }
    const saveJsonColor = async() =>{
        let json = {
            tid:localStorage.getItem("tid"), 
            version: localStorage.getItem( "taskVersion"), 
            cells: window.cellsColorsList,
            sortConfig: getSort()
        };
        postAxios("task/setStyle", JSON.stringify(json));
    }
    const getJsonColor = async() =>{
        let url = "task/getStyle?tid=" + localStorage.getItem("tid") + "&version=" + localStorage.getItem("taskVersion");
        await getAxios(url).then(response =>{
            if(!isEmpty(response)){
                const instance = handsonTable.current.hotInstance;
                setSortConfig(response.sortConfig);
                // window.cellsColorsList = response.cells;
                for (let index = 0; index < response.cells.length; index++) {
                    instance.setCellMeta(response.cells[index].row, response.cells[index].col, "className", response.cells[index].className);
                    window.cellsColorsList.push(response.cells[index]);
                }
                instance.render();
                instance.validateCells();
            }
        });
    }
    const splitTask = async() =>{
        saveData(true);
        const instance = handsonTable.current.hotInstance;
        let selection = instance.getSelected();
        let upcs = [];
        let rows = [];
        if(isEmpty(selection)){
            Swal.fire({icon: "info", title: "Espera!", text: "Selecciona items antes.", showConfirmButton: false, timer: 1500});
        }
        else{
            for (let index = 0; index < selection.length; index++) {
                let item = selection[index];
                let startRow = Math.min(item[0], item[2]) < 0 ? 0 : Math.min(item[0], item[2]);
                let endRow = Math.max(item[0], item[2]) < 0 ? 0: Math.max(item[0], item[2]);
                for (let rowIndex = startRow; rowIndex <= endRow; rowIndex += 1) {
                    let upc = instance.getDataAtRowProp(rowIndex, "upc");
                    if(!upcs.includes(upc)){
                        upcs.push(upc);
                        rows.push(rowIndex);
                    }
                }
            }
            if(upcs.length < instance.getData().length){
                let date = new Date().toLocaleDateString("es-Mx").replaceAll("/", "-");
                let name = localStorage.getItem("taskName") + "_" + date;

                const { value: newTaskName } = await Swal.fire({
                    title: 'Nombre de la tarea nueva',
                    input: 'text',
                    inputValue: name,
                    showCancelButton: true,
                    inputValidator: (value) => {
                      if (!value) {
                        return "Es necesario colocar un nombre.";
                      }
                    }
                  });
                  if (newTaskName) {
                    
                    let json = {
                        tid: localStorage.getItem("tid"),
                        title: newTaskName,
                        assignee: localStorage.getItem("taskAssigned"),
                        comment: "Split de tarea",
                        upcs: upcs
                    }
                    postAxios("task/split", JSON.stringify(json)).then(response => {
                        if(response.success){
                            Swal.fire({
                                icon: "success",
                                title: "Tarea nueva creada",
                                confirmButtonText: "Ok",
                            }).then((result) => {
                                window.location.href = "./taskManager";
                            })
                        }
                    });
                  }
            }
            else{
                Swal.fire({icon: "info", title: "Espera!", text: "Son demaciados items.", showConfirmButton: false, timer: 1500});
            }
        }
    }
    const revisionButtons = () =>{
        let level = localStorage.getItem("level");
        if(level !== "3"){
            return <Button variant="dark" onClick={()=>sendData("submitted")}><SendCheckFill/>{" "}Enviar</Button>
        }
        let status = localStorage.getItem("taskStatus");
        switch (status) {
            case "submitted":
                return (
                    <>
                        <Button variant="dark" onClick={()=>sendData("approved")}><BookmarkCheckFill/>{" "}Aprobar</Button>
                        <Button variant="dark" onClick={()=>sendData("rejected")}><BookmarkXFill/>{" "}Rechazar</Button>
                    </>
                )
            case "rejected":
                return <Button variant="dark" onClick={()=>sendData("submitted")}><SendCheckFill/>{" "}Enviar</Button>
            case "approved":
                return <></>
            default:
                return <Button variant="dark" onClick={()=>sendData("submitted")}><SendCheckFill/>{" "}Enviar</Button>
        }
    }
    const sendData = async(status) =>{
        let textMessage = "";
        let firstMessage = "";
        switch (status) {
            case "submitted":
                firstMessage = "¿Deseas enviar a revisión?";
                textMessage = "La tarea fue enviada";
                break;
            case "approved":
                firstMessage = "¿Deseas aprovar la tarea?";
                textMessage = "La tarea fue aprobada";
                break;
            case "rejected":
                firstMessage = "¿Deseas regresar la tarea?";
                textMessage = "La tarea fue rechazada";
                break;
            default:
                firstMessage = "¿Deseas enviar a revisión?";
                textMessage = "Tarea Actualizada";
                break;
        }
        Swal.fire({
            title: firstMessage,
            showCancelButton: true,
            confirmButtonText: "Si",
            confirmButtonColor: "#008f39",
            cancelButtonText: "No",
            cancelButtonColor: '#d33'
        }).then((result) => {
            if (result.isConfirmed) {
                saveData(true);
                let json ={tid: localStorage.getItem("tid"), status: status};
                postAxios("task/updateStatus", JSON.stringify(json) ).then(response => {
                    if(response.success){
                        if(status === "approve"){
                            getAxios("task/approve?tid="+localStorage.getItem("tid")).then(response2=>{
                            if(response.errors.length > 0){
                                
                            }
                            else{
                                Swal.fire({icon: "success", title: "", text: textMessage, showConfirmButton: false, timer: 1500});
                                window.location.href = "./taskManager";
                            }
                            });
                        }
                        else{
                            Swal.fire({icon: "success", title: "", text: textMessage, showConfirmButton: false, timer: 1500});
                            window.location.href = "./taskManager";
                        }
                    }
                });
            }
        });        
    }
    const clearData = () =>{
        const instance = handsonTable.current.hotInstance;
        let data = instance.getData();
        let headers = instance.getColHeader();

        let newData = [];
        for (let index = 0; index < data.length; index++) {
            let obj = {};
            let info = data[index];
            headers.forEach((element, i) => { obj[ substituteHeaders(element, "original")] = info[i]; });
            newData.push(obj);
        }
        data = null;

        for (let rowIndex = 0; rowIndex < newData.length; rowIndex++) {
            let features = [];
            for (let featureIndex = 1; featureIndex < 11; featureIndex++) {
                let feature = instance.getDataAtRowProp(rowIndex, "feature_" + featureIndex);
                feature = isEmpty(feature) ? "" : feature;
                features.push(feature);
                delete newData[rowIndex]["feature_" + featureIndex];
            }
            delete newData[rowIndex]["short_description_character_count"];
            delete newData[rowIndex]["dynamic_description_preview"];
            delete newData[rowIndex]["dynamic_description_character_count"];
            delete newData[rowIndex]["mercado_libre_preview_character_count"];
            delete newData[rowIndex]["walmart_description_preview"];

            newData[rowIndex]["features"] = features;

            newData[rowIndex]["battery_capacity"] = returnDefault0(newData[rowIndex]["battery_capacity"]);
            newData[rowIndex]["audio_power_output"] = returnDefault0(newData[rowIndex]["audio_power_output"]);
            newData[rowIndex]["frequency_response_max"] = returnDefault0(newData[rowIndex]["frequency_response_max"]);
            newData[rowIndex]["frequency_response_min"] = returnDefault0(newData[rowIndex]["frequency_response_min"]);
            newData[rowIndex]["speaker_quantity"] = returnDefault0(newData[rowIndex]["speaker_quantity"]);
            newData[rowIndex]["voltage"] = returnDefault0(newData[rowIndex]["voltage"]);
        }
        return newData;
    }
    const saveData = async(automatic = false) =>{
        let data = clearData();
        if (automatic){
            saveJsonColor();
            await postAxios("task/addDetail?tid=" + localStorage.getItem("tid")+"&version="+localStorage.getItem("taskVersion"), data );
        }
        else{
            Swal.fire({
                title: "¿Deseas guardar los cambios?",
                showCancelButton: true,
                confirmButtonText: "Guardar",
                confirmButtonColor: "#008f39",
                cancelButtonText: "No guardar",
                cancelButtonColor: '#d33'
              }).then((result) => {
                if (result.isConfirmed) {
                    saveJsonColor();
                    postAxios( "task/addDetail?tid=" + localStorage.getItem("tid")+"&version="+localStorage.getItem("taskVersion"), JSON.stringify( data) ).then(response=>{
                        // Swal.fire({icon: "info", title: "Espera!", text: "Selecciona un producto antes.", showConfirmButton: false, timer: 1500});
                        if(response.error.length > 0){
                            Swal.fire({icon: "error", title: "La tarea no se pudo guardar correctamente", confirmButtonText: "Ok" });
                        }
                        else{
                            Swal.fire({icon: "success", title: "Tarea guardada.", confirmButtonText: "Ok" });
                        }
                    });
                }
              })
        }
    }
    return(
        < >
            <SideMenu title={"Área de trabajo"} function={saveData}/>
            <Container className="marginTopContainer">
                <Row>
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item active href="#">Administrador de tareas</Breadcrumb.Item>
                            <Breadcrumb.Item active href="#">Área de trabajo</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <div className="box3">
                <Row>
                    <Col sm={12} mb={6} lg={6}>
                        <h3>{" " + localStorage.getItem("taskName")}</h3>
                    </Col>
                    <Col sm={12} mb={6} lg={6}>
                        <Stack direction="horizontal" gap={1}>
                            <ButtonGroup className="border ms-auto">
                                {revisionButtons()}
                                <Button variant="dark" onClick={()=>saveData()}><SaveFill/>{" "}Guardar</Button>
                                <Button variant="dark" onClick={()=>importFile()}><FileEarmarkArrowDownFill/>{" "}Importar</Button>
                                <DropdownButton as={ButtonGroup} variant={"dark"} title={<><FileEarmarkArrowUpFill/>{" "}Exportar</>} menuVariant="dark" >
                                    <Dropdown.Item eventKey="1" onClick={()=>exportarCSV()}>.csv</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={()=>exportarXLSX()}>.xlsx</Dropdown.Item>
                                </DropdownButton>
                                <Button variant="dark" onClick={()=>splitTask()}><LayoutSplit/>{" "}Separar</Button>
                            </ButtonGroup>
                        </Stack>
                    </Col>
                </Row>
            </div>
            <div className="box2">
                <Row className="marginBottomBox">
                    <Col sm={12} mb={6} lg={6}>
                        <Row>
                            <Col sm={12} mb={6} lg={6}>
                            <Stack direction="horizontal" gap={1}>
                                <Form.Check onChange={(e) => {swich = e.target.checked}} className="IniciaCon" type="switch" id="custom-switch" label="Inicia con: "/>
                                <InputGroup>
                                    <Form.Control  onChange={(e) => {getSearchField = e.target.value.toLowerCase()}} placeholder="Buscar..." aria-label="Buscar" aria-describedby="basic-addon2"/>
                                    <Button onClick={() => search()} variant="outline-secondary" id="button-addon2"><Search/></Button>
                                </InputGroup>
                            </Stack>
                            </Col><br/><br/>
                            <Col sm={12} mb={6} lg={6}>
                            <Stack direction="horizontal" gap={1}>
                                <Button title="Anterior producto buscado" onClick={() => {nextCoincidence(-1)}}  variant="light"><CaretLeftFill/></Button>{" "}
                                <Button title="Siguiente producto buscado" onClick={() => {nextCoincidence(1)}} variant="light"><CaretRightFill/></Button>{" "}
                                <ButtonColor colorseleccionado={colorseleccionado}/>
                                <Button title="Previsualización de producto seleccionado" variant="light" onClick={()=>openPreview()}><FileEarmarkRichtextFill/></Button>{" "}
                                <Button title="Subir imágenes a el producto seleccionado" variant="light" onClick={()=>openUploadPage("/UploadProductImages")}><Images/></Button>{" "}
                                <Button title="Subir archivos a el producto seleccionado" variant="light" onClick={()=>openUploadPage("/mediaFiles")}><CollectionFill/></Button>{" "}
                                {/* <Form.Select title="Cambiar tamaño de letra" className="selectFont" aria-label="Default select example">
                                    <option value="10px">10px</option>
                                    <option value="15px">15px</option>
                                    <option value="20px">20px</option>
                                    <option value="25px">25px</option>
                                    <option value="30px">30px</option>
                                    <option value="35px">35px</option>
                                    <option value="40px">40px</option>
                                </Form.Select> */}
                            </Stack>
                            </Col>
                        </Row>
                    </Col>
                    <Col/>
                </Row>
                <Row className="marginBottomBox">
                    <Col sm={12} mb={6} lg={6}>
                        <Stack direction="horizontal" gap={1}>
                            <Select placeholder="Departamentos" className="overTable selectOptions" onChange={(e) => updateSelection("dept", e)} options={getDeptOptions}></Select>
                            <Select placeholder="Clases" className="overTable selectOptions" onChange={(e) => updateSelection("class", e)} options={getClassOptions}></Select>
                            <Select placeholder="SubClases" className="overTable selectOptions" onChange={(e) => updateSelection("subclass", e)} options={getSubClassOptions}></Select>
                            <Select placeholder="Marcas" className="overTable selectOptions" onChange={(e) => updateSelection("brand", e)} options={getBrandOptions}></Select>
                        </Stack>
                    </Col>
                    <Col  sm={12} mb={6} lg={6}>
                        <Stack direction="horizontal" gap={1}>
                            <ButtonGroup className="border">
                                <Button variant="secondary" onClick={()=>search()}><Search/>{" "}Buscar</Button>
                            </ButtonGroup>
                        </Stack>
                    </Col>
                </Row>
                <Row className="marginBottomBox">
                    <Col>
                        <FloatingLabel controlId="floatingTextarea2" >
                            <Form.Control as="textarea" ref={textArea} onKeyDown={(e) => textAreaEnter(e)} onBlur={(e)=> isfocus(e)} style={{ height: "60px" }} />
                        </FloatingLabel>
                    </Col>
                </Row>
                <div className="tableDiv">
                    <Row>
                        <Col sm={12}>
                            <HotTable id="mainTable" ref={handsonTable} settings={handsonTableSettings}/>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default WorkingTask;