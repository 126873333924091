import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CloudArrowUp, FiletypeJpg, FiletypeGif, FiletypePng, FiletypeDoc, FiletypeDocx, FiletypePdf, FiletypeTxt, FiletypeMp4, FileEarmark, FiletypeCsv, FiletypeXls, FiletypeXlsx } from 'react-bootstrap-icons';
import Swal from "sweetalert2"
import { getAxios } from './commonFunctions';

const DropFileInput = (props) =>{
    const acceptFiles = "jpg, .jpeg, .gif, .png, .doc, .docx, .pdf, .txt, .avi, .mp4, .wmv, .csv, .xls, .xlsx";
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [deleteFileList, setDeleteFileList] = useState([]);
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    useEffect(()=>{
        getFiles();
        // eslint-disable-next-line
    },[]);

    const getFiles = async() => {
        let updatedList = [];
        await getAxios("task/media?upc=" + localStorage.getItem( 'upc_selected')).then(response => {
            if(response.success === true){
                for (let index = 0; index < response.media.length; index++) {
                    let obj = {
                        file:null, 
                        name: response.media[index].file, 
                        real_file: response.media[index].real_file, 
                        path: response.media[index].path
                    }
                    updatedList.push(obj);
                }
                setFileList(updatedList);
                props.onFileChange(updatedList, deleteFileList);
            }
        });
    }
    const onFileDrop = (e) => {
        const newFiles = e.target.files;
        let noaccept = false;
        if (newFiles) {
            let updatedList = [...fileList];
            for (let index = 0; index < newFiles.length; index++) {
                let extension = newFiles[index].name.split(".")[1];
                if(acceptFiles.includes(extension)){
                    if(255 > Math.ceil(newFiles[index].size/1048576)){
                        let object = {
                            file:newFiles[index],
                            name: newFiles[index].name,
                            real_file: newFiles[index].name,
                            path: window.URL.createObjectURL(newFiles[index]), 
                        };
                        updatedList = [...updatedList, object];
                    }
                    else{
                        Swal.fire({icon: "warning", title: "El archivo supera el peso limite.", showConfirmButton: false, timer: 1500 });
                    }
                }
                else{
                    noaccept = true;
                }                
            }
            if(noaccept){
                Swal.fire({icon: "warning", title: "Algunos archivos no son aceptados.", showConfirmButton: false, timer: 1500 });
            }
            setFileList(updatedList);
            props.onFileChange(updatedList, deleteFileList);
        }
    }
    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);

        let updateddeleteList = [...deleteFileList];
        updateddeleteList = [...updateddeleteList, file];
        setDeleteFileList(updateddeleteList);

        props.onFileChange(updatedList, updateddeleteList);
    }
    const getIcon = (name) => {
        let videos = "purple";
        let images = "orange";
        let sheet = "green";
        let texts = "blue";
        let baseColor = "gray";
        let adobe = "red";
        let estencion = name.split(".")[1];
        switch (estencion) {
            case "jpg":
                return <FiletypeJpg color={images} size={100} className="drop-icon"/>
            case "jpeg":
                return <FiletypeJpg color={images} size={100} className="drop-icon"/>
            case "gif":
                return <FiletypeGif color={images} size={100} className="drop-icon"/>
            case "png":
                return <FiletypePng color={images} size={100} className="drop-icon"/>
            case "doc":
                return <FiletypeDoc color={texts} size={100} className="drop-icon"/>
            case "docx":
                return <FiletypeDocx color={texts} size={100} className="drop-icon"/>
            case "pdf":
                return <FiletypePdf color={adobe} size={100} className="drop-icon"/>
            case "txt":
                return <FiletypeTxt color={texts} size={100} className="drop-icon"/>
            case "mp4":
                return <FiletypeMp4 color={videos} size={100} className="drop-icon"/>
            case "csv":
                return <FiletypeCsv color={sheet} size={100} className="drop-icon"/>
            case "xls":
                return <FiletypeXls color={sheet} size={100} className="drop-icon"/>
            case "xlsx":
                return <FiletypeXlsx color={sheet} size={100} className="drop-icon"/>
            default:
                return <FileEarmark color={baseColor} size={100} className="drop-icon"/>
        }
    }
    return(
        <>
            <div ref={wrapperRef} className="drop-file-input" onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                <div className="drop-file-input__label">
                    <CloudArrowUp size={100}/>
                    <p>Arrastra archivos multimedia. (Max 255 MiB)</p>
                </div>
                <input type="file" multiple accept={acceptFiles} value="" onChange={onFileDrop}/>
            </div>
            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">
                            Listo para actualizar:
                        </p>
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    {getIcon(item.name)}
                                    <div className="drop-file-preview__item__info">
                                        <a href={item.path} target="_blank" rel="noreferrer">{item.name || item.file}</a>
                                    </div>
                                    <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
        </>
    );
}

DropFileInput.prototypes = {
    onFileChange: PropTypes.func
}

export default DropFileInput;