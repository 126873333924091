import Handsontable from 'handsontable';
import hexRgb from 'hex-rgb';

const parseTags = text => ((text && text.split(",")) || []).filter(t => !!t);
const createRendererTag = text => createTag(text, { display: "inline", color: "lightgray" });
const createTag = (text, { display, color, onRemove } ) => {
    const span = document.createElement("span");
    span.style.cssText = `padding: 0 5px; margin-right: 2px; background: ${color}; border-radius: 1em; white-space: nowrap; display: ${display}; margin-top: 2px`
    span.textContent = text;
    if (onRemove) {
        const button = document.createElement("button");
        button.textContent = "x";
        button.className = "remove";
        button.onclick = onRemove;
        span.append(button);
    }
    return span;
}
export const HiperLinksRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.BaseRenderer(instance, td, row, column, prop, value, cellProperties);
    Handsontable.dom.empty(td);
    const a = document.createElement("a");
    a.text = value;
    a.href = value;
    a.target = "_blank";
    td.append(a);
}
export const tagsRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.BaseRenderer(instance, td, row, column, prop, value, cellProperties);
    Handsontable.dom.empty(td);
    value && td.append(...parseTags(value).map(createRendererTag));
}

// Renderer product creation or edit
const stringToColour = (str) => {
    if(str === "" || str === null || str === undefined){
        return "#ffffff"
    }
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour
}
const textColor = (color) =>{
    let rgb = hexRgb(color.replace("#",""));
    var o = Math.round(((parseInt(rgb.red) * 299) + (parseInt(rgb.green) * 587) + (parseInt(rgb.blue) * 114)) /1000);
    return o > 125 ? "#000000" : "#FFFFFF";
}
export const TextRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.TextRenderer(instance, td, row, column, prop, value, cellProperties);
    let color = stringToColour(instance.getDataAtRowProp(row, "Company"));
    td.style.backgroundColor = color;
    td.style.color = textColor(color);
}
export const AutocompleteRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.AutocompleteRenderer(instance, td, row, column, prop, value, cellProperties);
    let color = stringToColour(instance.getDataAtRowProp(row, "Company"));
    td.style.backgroundColor = color;
    td.style.color = textColor(color);
}
export const NumericRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.NumericRenderer(instance, td, row, column, prop, value, cellProperties);
    let color = stringToColour(instance.getDataAtRowProp(row, "Company"));
    td.style.backgroundColor = color;
    td.style.color = textColor(color);
}
export const CheckboxRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.CheckboxRenderer(instance, td, row, column, prop, value, cellProperties);
    let color = stringToColour(instance.getDataAtRowProp(row, "Company"));
    td.style.backgroundColor = color;
    td.style.color = textColor(color);
}
export const LimitValueRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.NumericRenderer(instance, td, row, column, prop, value, cellProperties);
    let color = stringToColour(instance.getDataAtRowProp(row, "Company"));
    td.style.backgroundColor = color;
    td.style.color = textColor(color);
    const _value = instance.getDataAtRowProp(row, prop);
    if(_value < 0){ 
        instance.setDataAtRowProp(row, prop, 0);
        td.innerHTML = 0;
    }
    else if(_value > 100){
        instance.setDataAtRowProp(row, prop, 100);
        td.innerHTML = 100;
    }
}
export const IntegerRenderer = (instance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.NumericRenderer(instance, td, row, column, prop, value, cellProperties);
    let color = stringToColour(instance.getDataAtRowProp(row, "Company"));
    td.style.backgroundColor = color;
    td.style.color = textColor(color);

    const _value = parseInt(instance.getDataAtRowProp(row, prop));
    instance.setDataAtRowProp(row, prop, _value);
    td.innerHTML = _value;    
}