import React, { useEffect, useState } from "react";
import SideMenu from "../Components/sideMenu";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Swal from "sweetalert2"

//import { clearSaveData, postAxios, putFetch, deleteFetch } from "../Components/commonFunctions";
//import { getAxios, tempImgURL } from "../Components/commonFunctions";
import { clearSaveData, getAxios, postAxios, putFetch, deleteFetch, tempImgURL } from "../Components/commonFunctions";

import DropImages from "../Components/DropImages"

const UploadProductImages = (props) =>{

    const [product, setProduct] = useState([]);
    const [lifestyle, setLifestyle] = useState([]);
    const [packaging, setPackaging] = useState([]);
    const [specifications, setSpecifications] = useState([]);
    const [deleted, setDeleted] = useState([]);

    const style = {marginTop:"20px"};

    useEffect(()=>{
        clearSaveData();
        geImages();
        // eslint-disable-next-line
    },[]);

    const updateDelete = (file) =>{
        setDeleted([file, ...deleted]);
    }
    const sortList = (a, b) =>{
        if (a.ranking > b.ranking) {
            return 1;
        }
        if (a.ranking < b.ranking) {
            return -1;
        }
        return 0;
    }
    const geImages = async() => {
        let url ="task/getTmpImages?tid=" + localStorage.getItem("tid") + "&upc=" + localStorage.getItem("upc_selected");

        let productList=[];
        let lifeStyleList=[];
        let packagingList=[];
        let SpecificationsList=[];

        getAxios(url).then(response=>{
            for (let index = 0; index < response.length; index++) {
                let obj = {
                    id: response[index].file, 
                    file: "", 
                    preview: tempImgURL + response[index].file, 
                    rank: response[index].ranking, 
                    filename: response[index].file
                };
                switch(response[index].type){
                    case "product":
                        productList.push(obj);
                        break;
                    case "lifestyle":
                        lifeStyleList.push(obj);
                        break;
                    case "packaging":
                        packagingList.push(obj);
                        break;
                    case "spec":
                        SpecificationsList.push(obj);
                        break;
                    default:
                        //do nothing
                        break;
                }
            }
            setProduct(productList.sort(sortList));
            setLifestyle(lifeStyleList.sort(sortList));
            setPackaging(packagingList.sort(sortList));
            setSpecifications(SpecificationsList.sort(sortList));
        });
    }
    const sendImageList = (list, type) => {
        for (let index = 0; index < list.length; index++) {
            let file = list[index].file;
            if(file !== ""){
                UpdateImages(file, type, index);
            }
            else{
                UpdateImagesTemp(list[index].filename, index);
            }
        }
    }    
    const UpdateImages = (file, kind, ranking) =>{
        let re = /(?:\.([^.]+))?$/;
        let ext = re.exec(file["name"])[1].toLowerCase();
        const formData = new FormData();
        formData.append( 'image', file );
        formData.append( 'tid',  localStorage.getItem("tid") );
        formData.append( 'upc', localStorage.getItem("upc_selected") );
        formData.append( 'type', kind );
        formData.append( 'ranking', ranking );
        formData.append( 'ext', "." + ext );        
        postAxios("task/uploadTmpImg?tid=" + localStorage.getItem("tid"), formData, "multipart/form-data").then(response => {
            console.log("send");
        });
    }
    const UpdateImagesTemp = (filename, rank) =>{
        let json = {"filename":filename, "ranking": rank };
        putFetch("product/updateImgs", JSON.stringify(json)).then(response => {
            console.log("update");
        });
    }
    const deleteImages = async() =>{
        for (let index = 0; index < deleted.length; index++) {
            let json = {"filename": deleted[index][0].filename, "tid": localStorage.getItem( "tid")};
            deleteFetch( "task/uploadTmpImg" , JSON.stringify(json)).then(response => {
                console.log("delete");
            });
        }
    }
    const cleanData = (list) =>{
        let newList = [];
        for (let index = 0; index < list.length; index++) {
            list[index].file = "";
            newList.push(list[index]);
        }
        return newList;
    }
    const save = async() =>{
        let promises = [];        
        promises.push(deleteImages());
        promises.push(sendImageList(product, "product"));
        promises.push(sendImageList(lifestyle, "lifestyle"));
        promises.push(sendImageList(packaging, "packaging"));
        promises.push(sendImageList(specifications, "spec"));
        Promise.all(promises).then( async() => {
            await setDeleted([]);
            await setProduct(cleanData(product));
            await setLifestyle(cleanData(lifestyle));
            await setPackaging(cleanData(packaging));
            await setSpecifications(cleanData(specifications));
            Swal.fire({icon: "success", title: "Terminado.", text: "Todos los archivos fueron actualizados", showConfirmButton: true});
        });
    }
    return(
        <>
        <SideMenu title="Actualizar imágenes"/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="#">Administrador de tareas</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">Área de trabajo</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">Actualizar imágenes</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <h3>{localStorage.getItem( "name_selected")}</h3>
                <h6>{"UPC: " + localStorage.getItem( "upc_selected") + " | Item Number: " + localStorage.getItem( "itemNumber_selected")}</h6>
            </Row>
            <Row>
                <Button onClick={()=>save()} variant="danger" className="fullWidth redButtonColor">Actualizar</Button>
                <Tabs  defaultActiveKey="Product" id="uncontrolled-tab-example" className="mb-3" style={style} >
                    <Tab eventKey="Product" title="Product">
                        <DropImages Pictures={product} updatePictures={setProduct} updatedelete={updateDelete}></DropImages>
                    </Tab>
                    <Tab eventKey="Lifestyle" title="Lifestyle">
                        <DropImages Pictures={lifestyle} updatePictures={setLifestyle} updatedelete={updateDelete}></DropImages>
                    </Tab>
                    <Tab eventKey="Packaging" title="Packaging">
                        <DropImages Pictures={packaging} updatePictures={setPackaging} updatedelete={updateDelete}></DropImages>
                    </Tab>
                    <Tab eventKey="Specifications" title="Specifications">
                        <DropImages Pictures={specifications} updatePictures={setSpecifications} updatedelete={updateDelete}></DropImages>
                    </Tab>
                </Tabs>
            </Row>
        </Container>
        </>
    );
}

export default UploadProductImages;