import React, { useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {CloudArrowUp} from 'react-bootstrap-icons';

const DropImages = (props) =>{

    const wrapperRef = useRef(null);
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const files = e.target.files;
        if (files) {
            handleFiles(files);
        }
    }
    const handleFiles = async (files) => {
        let extencions = ["jpg", "png", "jpeg"]; //"pdf"
        let re = /(?:\.([^.]+))?$/;
        let values = [];
        for (let index = 0; index < files.length; index++) {
            let ext = re.exec(files[index].name)[1].toLowerCase();
            if(extencions.includes(ext)){
                let obj = {id:files[index].name, file:files[index], preview:URL.createObjectURL(files[index])};
                values.push(obj);
            }
        }
        props.updatePictures([...props.Pictures, ...values]);
    }
    const removeFile = (file)=>()=>{
        let index = props.Pictures.findIndex(x => x.id === file.id);
        let deletefile = props.Pictures.splice(index, 1)
        props.updatePictures(props.Pictures);
        props.updatedelete(deletefile);
    }
    const handleOnDragEnd = async (result) =>{
        if(!result.destination) return;
        const Items = Array.from(props.Pictures);
        const [reorderedItem] = Items.splice(result.source.index, 1);
        Items.splice(result.destination.index, 0, reorderedItem)
        props.updatePictures(Items);
    }

    return(
        <>
            <div className ="thumbsContainer">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="PicturesList" direction="horizontal">
                        {(provided) => (
                            <div className="PicturesBox" {...provided.droppableProps} ref={provided.innerRef}>
                                {props.Pictures.map(({id, file, preview}, index) => {
                                    return (
                                    <Draggable key={id} draggableId={id} index={index}>
                                        {(provided) =>(
                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <div className="thumb">
                                                <div className="thumbInner">
                                                    <img alt="" src={preview}/>
                                                    <button className="closeButton" onClick={removeFile({id, file, preview})}>X</button>
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div ref={wrapperRef} className="drop-file-input" onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                <div className="drop-file-input__label">
                    <CloudArrowUp size={100}/>
                    <p>Arrastra imágenes.</p>
                </div>
                <input type="file" multiple accept="image/*" value="" onChange={onFileDrop}/>
            </div>
        </>
    );
}

export default DropImages;