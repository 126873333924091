import React, { useState, useEffect } from "react";
import SideMenu from "../Components/sideMenu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import DropFileInput from "../Components/DropMediaInput";
import { clearSaveData, deleteFetch, postAxios } from "../Components/commonFunctions";
import Swal from "sweetalert2"

const UpdateMediaFiles=(props)=>{

    const [getUploadFiles, setUploadFiles] = useState([]);
    const [getDeleteFiles, setDeleteFiles] = useState([]);

    useEffect(()=>{
        clearSaveData();
        // eslint-disable-next-line
    },[]);

    const onFileChange = (uploadFiles, deleteFiles) => {
        setUploadFiles(uploadFiles);
        setDeleteFiles(deleteFiles);
    }
    const update = async() =>{
        let promises = [];
        for (let index = 0; index < getUploadFiles.length; index++) {
            if(getUploadFiles[index].file !== null){
                let formData = new FormData();
                formData.append( "file", getUploadFiles[index].file);
                formData.append( "tid", localStorage.getItem("tid"));
                formData.append( "upc", localStorage.getItem( "upc_selected") );
                promises.push(postAxios("task/media", formData, "multipart/form-data"));
            }
        }
        for (let index = 0; index < getDeleteFiles.length; index++) {
            let payload = {"filename": getDeleteFiles[index].real_file };
            promises.push(deleteFetch("task/media", JSON.stringify(payload)));
        }
        // if(promises.length > 0){
        //     Swal.fire({icon: "info", title: "Actualizando...", showConfirmButton: false, timerProgressBar: true, didOpen:()=>{Swal.showLoading()}});
        // }
        Promise.all(promises).then(() => {
            Swal.fire({icon: "success", title: "Terminado.", text: "Todos los archivos fueron actualizados", showConfirmButton: true});
        });
    }
    return(
        <>
            <SideMenu title="Actualización de multimedia"/>
            <Container className="marginTopContainer">
                <Row>
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item active href="/taskManager">Administrador de tareas</Breadcrumb.Item>
                            <Breadcrumb.Item active href="#">Área de trabajo</Breadcrumb.Item>
                            <Breadcrumb.Item active href="#">Actualización de multimedia</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <h3>{localStorage.getItem( "name_selected")}</h3>
                    <h6>{"UPC: " + localStorage.getItem( "upc_selected")}</h6>
                    <h6>{"Item Number : " + localStorage.getItem( "itemNumber_selected")}</h6>
                </Row>
                <Row>
                    <div className="box">
                        <h2 className="header">Actualización de archivos multimedia.</h2>
                        <Button onClick={()=>update()} variant="danger" className="fullWidth redButtonColor">Actualizar</Button>
                        <br/>
                        <br/>
                        < DropFileInput onFileChange={(uploadFiles, deleteFiles) => onFileChange(uploadFiles, deleteFiles)} />
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default UpdateMediaFiles;