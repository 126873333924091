import Handsontable from 'handsontable';

class TagsEditor extends Handsontable.editors.AutocompleteEditor {
    beginEditing(newInitialValue, event){
        super.beginEditing("", event);
    }
    finishEditing(restoreOriginalValue, ctrlDown, callback){
        const value = this.htEditor && this.htEditor.getSelectedLast()
        ? this.htEditor.getInstance().getValue()
        : this.getValue();
        super.finishEditing(restoreOriginalValue || !value || !value.trim(), ctrlDown, callback)
    }
    saveValue(value, ctrlDown){
        let tags = [];
        if(this.rawChoices.includes(this.parseTags(value[0][0])[0])){
            tags = [...this.parseTags(this.originalValue), ...this.parseTags(value[0][0])]
        }
        else{
            tags = [...this.parseTags(this.originalValue)]
        }
        const uniqueTags = tags.filter((tag, i) => tags.indexOf(tag) ===i)
        super.saveValue([[this.tagsToString(uniqueTags)]], ctrlDown)
    }
    createElements(){
        super.createElements();
        const { rootDocument } = this.hot;
        this.TAGS_CONTAINER = rootDocument.createElement("div");
        this.TAGS_CONTAINER.style.cssText = `background: #eee; padding: 5px; box-shadow:         inset 0 2px 5px rgba(0,0,0,.2); max-height: 3em; overflow-y: auto;`;
        this.TEXTAREA_PARENT.prepend(this.TAGS_CONTAINER);
    }
    open(){
        Handsontable.dom.empty(this.TAGS_CONTAINER);
        const removableTag = tag =>
            this.createEditorTag(tag, () => {
                const { instance, row, col, cellProperties } = this;
                const tags = this.parseTags(this.originalValue).filter(t => t !== tag);
                instance.setDataAtCell(row, col, this.tagsToString(tags));
                cellProperties.source.push(tag);
                cellProperties.source.sort();
            });
        
        this.TAGS_CONTAINER.append(...this.parseTags(this.originalValue).map(removableTag));
        super.open();
    }
    refreshDimensions(){
        super.refreshDimensions();
        this.TAGS_CONTAINER.style.width = this.TEXTAREA.style.width;
    }
    prepare(td, row, col, prop, value, cellProperties){
        const tags = this.parseTags(value);      
        cellProperties.source = cellProperties.source.filter(t => !tags.includes(t));
        super.prepare(td, row, col, prop, value, cellProperties);
    }
    createTag(text, { display, color, onRemove } ){
        const span = document.createElement("span");
        span.style.cssText = `padding: 0 5px; margin-right: 2px; background: ${color}; border-radius: 1em; white-space: nowrap; display: ${display}; margin-top: 2px`
        span.textContent = text;
        if (onRemove) {
            const button = document.createElement("button");
            button.textContent = "x";
            button.className = "remove";
            button.onclick = onRemove;
            span.append(button);
        }
        return span;
    }
    parseTags = text => ((text && text.split(",")) || []).filter(t => !!t);
    tagsToString = tags => (tags && tags.join(",")) || "";
    createEditorTag = (text, onRemove) => this.createTag(text, { display: "inline-block", color: "lightblue", onRemove });
}

export default TagsEditor;

