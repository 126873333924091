import React, { useState, useEffect } from "react";
import SideMenu from "../Components/sideMenu";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import DropFileInput from "../Components/DropFileInput";
import Swal from "sweetalert2"
import { postAxios, clearSaveData } from "../Components/commonFunctions";

const UpdateProductTypes=(props)=>{

    const [getFiles, setFiles] = useState([]);
    const [getClearData, setClearData] = useState(new Date());

    useEffect(()=>{
        if(localStorage.getItem("level") !== "3"){
            window.location.href = "/taskManager";
        }
        clearSaveData();
        // eslint-disable-next-line
    },[]);

    const onFileChange = (files) => {
        setFiles(files);
    }
    const updateProductTypes = () =>{
        if( getFiles.length === 0 ){
            Swal.fire({ icon: "info", title: "Espera!", text: "No hay un archivo para actualizar.", showConfirmButton: false, timer: 1500 });
        }
        else{
            let formData = new FormData();
            formData.append( "csv", getFiles[0] );
            postAxios("task/updateSubstitutes", formData, "multipart/form-data").then(response => {
                if(response.success === true){
                    setClearData(new Date());
                    Swal.fire({ icon: "success", title: "Exito!", text: "Base de datos actualizada.", showConfirmButton: false, timer: 1500 });
                }
                else{
                    Swal.fire({ icon: "error", title: "Error!", text: "No se pudo actualizar.", showConfirmButton: false, timer: 1500 });
                }
                
            }).catch(error => {
                Swal.fire({ icon: "error", title: "Error!", text: "No se pudo actualizar.", showConfirmButton: false, timer: 1500 });
            });
        }
    }
    return(
        <>
        <SideMenu title="Actualización de product types"/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="/taskManager">Administrador de tareas</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">Actualización de product types</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <div className="box">
                    <h2 className="header">Actualización de product types</h2>
                    <Button onClick={()=>updateProductTypes()} variant="danger" className="fullWidth redButtonColor">Actualizar</Button>
                    <br/>
                    <br/>
                    < DropFileInput clearData={getClearData} onFileChange={(files) => onFileChange(files)} />
                </div>
            </Row>
        </Container>
        </>
    );
}

export default UpdateProductTypes;