import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import viastaraLogo from "../img/viastaraLogo.svg";
import Swal from "sweetalert2"
import { tryLogin, setCookie, getCookie } from "../Components/commonFunctions"
import {PersonFill, LockFill} from 'react-bootstrap-icons';

const Login =(props)=>{
    const [getUser, setUser] = useState("");
    const [getPassword, setPassword] = useState("");
    const [getRememberMe, setRememberMe] = useState();

    useEffect(()=>{
        localStorage.setItem( "asignados", null);
        getLocalStorageRememberMe();
        checkLogin();
        // eslint-disable-next-line
    },[]);

    const _handleKeyDown=(e)=>{
        if(e.key==="Enter"){loginButton();}
    }
    const checkLogin =()=>{
        let token = getCookie();
        if( token !== null && token !== undefined){
            window.location.href = "/taskManager";
        }
    }
    const getLocalStorageRememberMe=()=>{
        let RememberMe = localStorage.getItem( "rememberMe" );
        if(RememberMe === "true"){
            setUser(localStorage.getItem("email"));
            setPassword(localStorage.getItem("password"));
            setRememberMe(true);
        }
        else{
            setRememberMe(false);
        }
    }
    const loginButton =()=>{
         tryLogin(getUser, getPassword).then(response =>{
            if(response.success === false || response === undefined){
                Swal.fire({ icon: "warning", title: "Espera!", text: "Usuario o contraseña incorrectos", showConfirmButton: false, timer: 1500 });
            }
            else{
                localStorage.setItem("email", getUser);
                localStorage.setItem("rememberMe", getRememberMe);
                localStorage.setItem("uid", response.uid);
                localStorage.setItem("user", response.name + " " + response.lastname);
                localStorage.setItem("UserName", response.user);
                localStorage.setItem("level", response.level);
                setCookie(response.token);
                // document.cookie = 'token=' + response.token + '; Max-Age=86400; path=/';
                window.location.href = "/taskManager";
            }
         });
    }
    return(
        <div className="loginBackground">
            <div className="container">
                <div className="d-flex justify-content-center align-items-center fullHeight">
                    <div>
                        <img src={viastaraLogo} alt=""></img>
                        <div className="d-flex justify-content-center">
                            <p className="whiteText">VLC / Sistema General</p>
                        </div>
                        <div className="input-group">
                            <span className="input-group-text"><PersonFill/></span>
                            <input value={getUser} onChange={(e)=>setUser(e.target.value)} onKeyDown={(e) => _handleKeyDown(e)} type="text" className="form-control" placeholder="Enter email or username" autoComplete="username email" aria-label="User"/>
                        </div>
                        <div className="input-group">
                            <span className="input-group-text"><LockFill/></span>
                            <input value={getPassword} onChange={(e)=>setPassword(e.target.value)} onKeyDown={(e) => _handleKeyDown(e)} type="password" className="form-control" placeholder="Password" aria-label="Password"/>
                        </div>
                        <div className="form-check d-flex justify-content-center" >
                            <input className="form-check-input" type="checkbox" value={getRememberMe} onChange={(e)=>setRememberMe(e.target.checked)} id="flexCheckDefault"/>
                            <label className="form-check-label whiteText" htmlFor="flexCheckDefault">Remember me</label>
                        </div>
                        <div>
                            <Button onClick={()=>loginButton()} variant="danger" className="fullWidth redButtonColor">LOGIN</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;