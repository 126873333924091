import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export const mainURL = "https://cato.skleo.com/api/v1/";
export const tempImgURL = "https://cato.skleo.com/img/tmp/";
export const productImgURL = "https://cato.skleo.com/img/products/";
export const columnOrder = [
    "itemNumber", // A
    "upc", // B
    "ean", // C
    "asin", // D
    "dept", // E
    "class", // F
    "subclass", // G
    "brand", // H
    "description", // I
    "description_es", // J
    "color", // K
    "color_brand", // L
    "url", // M
    "video_url", // N
    "ext_en", // O
    "ext_es", // P
    "feature_1", // Q
    "feature_2", // R
    "feature_3", // S
    "feature_4", // T
    "feature_5", // U
    "feature_6", // V
    "feature_7", // W
    "feature_8", // X
    "feature_9", // Y
    "feature_10", // Z
    "compatibility", // AA
    "variation_type", //AB
    "product_type", //AC
    "product_name", //AD
    "model", //AE
    "name", //AF
    "short_description_character_count", //AG
    "description_features", //AH
    "description_extended_features", //AI
    "dynamic_description_preview", //AJ
    "dynamic_description_character_count", //AK
    "ml_manual_title", //AL
    "mercado_libre_preview_character_count", //AM
    "walmart_description_preview", //AN
    "amazon_description_preview", //AO
    "brand_collection_tags", //AP
    "wired_data", //AQ
    "wireless_connectivity", //AR
    "media_streaming_protocol", //AS
    "cloud_storage", //AT
    "power_source", //AU
    "battery_capacity", //AV
    "voltage", //AW
    "audio_power_output", //AX
    "frequency_response_max", //AY
    "frequency_response_min", //AZ
    "input_ports", //BA
    "output_ports", //BB
    "audio_channels", //BC
    "speaker_quantity", //BD
    "recording_resolution_max", //BE
    "angle_of_view", //BF
    "display_resolution", //BG
    "screen_protection_material", //BH
    "sensors", //BI
    "health_fitness_tracking", //BJ
    "device_additional_features", //BK
    "protection_rating", //BL
    "device_compatibility", //BM
    "os_compatibility", //BN
    "keywords", //BO
    "country_origin", //BP
    "package_content", //BQ
    "package_content_ex", //BR
    "old_package_content", //BS
    "material_composition", //BT
    "power_specs", //BU
    "lithium_battery", //BV
    "wireless", //BW
    "lifetime_warranty", //BX
    "warranty_length", //BY
    "warranty_unit", //BZ
    "length", //CA
    "width", //CB
    "height", //CC
    "pkg_length", //CD
    "pkg_width", //CE
    "pkg_height", //CF
    "dimensions_unit", //CG
    "weight", //CH
    "pkg_weight", //CI
    "weight_unit" //CJ
];

export const isEmpty = (value) =>{
    let list = [null, undefined, "null", "undefined", ""];
    return list.includes(value);
}
export const clearSaveData = ()=>{
    localStorage.setItem( "createTask", null);
    localStorage.setItem( "createNewTask", null);
    localStorage.setItem( "createIncompleteTask", null);
}
export const substituteHeaders = (header, toReturn="substitute") => {
    let data = [
        {original:"stock", substitute:"Stock"},
        {original:"status", substitute:"Status"},
        {original:"created", substitute:"Created"},
        {original:"percent", substitute:"Percent"},
        {original:"itemNumber", substitute:"Item Number"},
        {original:"upc", substitute:"UPC"},
        {original:"ean", substitute:"EAN"},
        {original:"asin", substitute:"ASIN"},
        {original:"dept", substitute:"Department"},
        {original:"class", substitute:"Class"},
        {original:"subclass", substitute:"Subclass"},
        {original:"brand", substitute:"Brand"},
        {original:"description", substitute:"Item Description"},
        {original:"description_es", substitute:"Item Description (Español)"},
        {original:"color", substitute:"Color Title"},
        {original:"color_brand", substitute:"Color Brand"},
        {original:"url", substitute:"Web Link"},
        {original:"video_url", substitute:"Video Link"},
        {original:"ext_en", substitute:"Extended Description"},
        {original:"ext_es", substitute:"Extended Description (Español)"},
        {original:"feature_1", substitute:"Feature 1"},
        {original:"feature_2", substitute:"Feature 2"},
        {original:"feature_3", substitute:"Feature 3"},
        {original:"feature_4", substitute:"Feature 4"},
        {original:"feature_5", substitute:"Feature 5"},
        {original:"feature_6", substitute:"Feature 6"},
        {original:"feature_7", substitute:"Feature 7"},
        {original:"feature_8", substitute:"Feature 8"},
        {original:"feature_9", substitute:"Feature 9"},
        {original:"feature_10", substitute:"Feature 10"},
        {original:"compatibility", substitute:"Compatibility"},
        {original:"variation_type", substitute:"Variation Type"},
        {original:"product_type", substitute:"Product Type"},
        {original:"product_name", substitute:"Product Name"},
        {original:"model", substitute:"Model Name"},
        {original:"name", substitute:"Short Description"},
        {original:"short_description_character_count", substitute:"Short Description Character Count"},
        {original:"description_features", substitute:"Description Features"},
        {original:"description_extended_features", substitute:"Description Extended Features"},
        {original:"dynamic_description_preview", substitute:"Dynamic Description Preview"},
        {original:"dynamic_description_character_count", substitute:"Dynamic Description Character Count"},
        {original:"ml_manual_title", substitute:"Mercado Libre Description"},
        {original:"mercado_libre_preview_character_count", substitute:"Mercado Libre Preview Character Count"},
        {original:"walmart_description_preview", substitute:"Walmart Description Preview"},
        {original:"brand_collection_tags", substitute:"Brand Collection Tags"},
        {original:"device_compatibility", substitute:"Device Compatibility"},
        {original:"os_compatibility", substitute:"OS Compatibility"},
        {original:"keywords", substitute:"Keywords"},
        {original:"country_origin", substitute:"Country of Origin"},
        {original:"package_content", substitute:"Package Content Formula"},
        {original:"old_package_content", substitute:"Old Package Content"},
        {original:"package_content_ex", substitute:"Package Content"},
        {original:"material_composition", substitute:"Material Composition"},
        {original:"power_specs", substitute:"Power Specs"},
        {original:"lithium_battery", substitute:"Lithium Battery"},
        {original:"wireless", substitute:"Wireless Product"},
        {original:"lifetime_warranty", substitute:"Lifetime Warranty"},
        {original:"warranty_length", substitute:"Warranty Length"},
        {original:"warranty_unit", substitute:"Warranty Unit"},
        {original:"length", substitute:"Product Length"},
        {original:"width", substitute:"Product Width"},
        {original:"height", substitute:"Product Height"},
        {original:"pkg_length", substitute:"Package Length"},
        {original:"pkg_width", substitute:"Package Width"},
        {original:"pkg_height", substitute:"Package Height"},
        {original:"dimensions_unit", substitute:"Dimensions Unit"},
        {original:"weight", substitute:"Product Weight"},
        {original:"pkg_weight", substitute:"Package Weight"},
        {original:"weight_unit", substitute:"Weight Unit"},
        {original:"amazon_description_preview", substitute:"Amazon Description Preview"},
        {original:"wired_data", substitute: "Wired Data"},
        {original:"wireless_connectivity", substitute: "Wireless Connectivity"},
        {original:"media_streaming_protocol", substitute: "Media Streaming Protocol"},
        {original:"cloud_storage", substitute: "Cloud Storage"},
        {original:"power_source", substitute: "Power Source"},
        {original:"battery_capacity", substitute: "Battery Capacity (mAh)"},
        {original:"voltage", substitute: "Voltage (V)"},
        {original:"audio_power_output", substitute: "Audio Power Output (RMS) (W)"},
        {original:"frequency_response_max", substitute: "Frequency Response Max (Hz)"},
        {original:"frequency_response_min", substitute: "Frequency Response Min (Hz)"},
        {original:"input_ports", substitute: "Input Ports"},
        {original:"output_ports", substitute: "Output Ports"},
        {original:"audio_channels", substitute: "Audio Channels"},
        {original:"speaker_quantity", substitute: "Speaker Quantity"},
        {original:"recording_resolution_max", substitute: "Recording Resolution Max"},
        {original:"angle_of_view", substitute: "Angle Of View"},
        {original:"display_resolution", substitute: "Display Resolution (px)"},
        {original:"screen_protection_material", substitute: "Screen Protection Material"},
        {original:"sensors", substitute: "Sensors"},
        {original:"health_fitness_tracking", substitute: "Health Fitness Tracking"},
        {original:"device_additional_features", substitute: "Device Additional Features"},
        {original:"protection_rating", substitute: "Protection Rating"}
    ];
    let type = ["original", "substitute"];
    let index = type.indexOf(toReturn);
    type.splice(index,1);
    let value = data.find(item => item[type[0]] === header);
    return value ? value[toReturn] : header;
}
export const compareObjects = (obj1, obj2) => {
    let obj1Values = Object.values(obj1);
    let obj2Values = Object.values(obj2);
    if(obj1Values.length !== obj2Values.length){
        return false;
    }
    for (let index = 0; index < obj1Values.length; index++) {
        if(obj1Values[index] !== obj2Values[index]){
            return false;
        }
    }
    return true;
}
export const setCookie = (token) => {
    cookies.set("token", token, {path: "/", maxAge: 86400});
}
export const getCookie = () => {
    return cookies.get("token");
}
export const isLogin = () =>{
    let token = getCookie();
    if(!token){
        window.location.href = "/";
    }
}
export const logout = () => {
    cookies.remove("token");
}
export const tryLogin = async (user="", password="") => {
    let response = axios.post(mainURL + "auth", '{"user": "' + user.toLowerCase() + '", "pass": "' + password + '"}', {
        validateStatus: function (status) {
            return status ;
    }});
    return (await response).data;
}
export const getAxios = async (url) => {
    let headerProposal ={headers:{"X-Viastara-Token": getCookie()}};
    let response = axios.get(mainURL + url , headerProposal);
    return (await response).data;
}
export const postAxios = async (url, json, contentType="application/json") => {
    let headerProposal ={headers:{"X-Viastara-Token": getCookie(), "Content-Type": contentType}};
    let response = axios.post(mainURL + url, json, headerProposal);
    return (await response).data;
}
export const deleteAxios = async (url, json) => {
    let headerProposal ={headers:{'X-Viastara-Token': getCookie(), "Content-Type": "application/json"}};
    let response = axios.delete(mainURL + url, json , headerProposal);
    return (await response).data;
}
export const deleteAxios_2 = async (url, json) => {
    let headerProposal ={'X-Viastara-Token': getCookie(), "Content-Type": "application/json"};
    let response = axios.delete(mainURL + url, {"data": json , "headers": headerProposal});
    return (await response).data;
}
export const formulaWalmart = (productName, productType, brand, descriptionFeatures, modelName, substituteList) => {
    let display_Name = [];

    if(!isEmpty(productName)){
        display_Name.push(productName);
    }
    else{
        let indexsubstitute = substituteList.findIndex(x => x.type === productType);
        let value = (indexsubstitute > -1) ? substituteList[indexsubstitute].substitute.replaceAll("-"," ") : "";
        if (value !== ""){
            display_Name.push( value );
        }
    }
    if(!isEmpty(descriptionFeatures)){
        display_Name.push(descriptionFeatures);
    }
    if(!isEmpty(brand)){
        display_Name.push(brand);
    }
    if(!isEmpty(modelName)){
        display_Name.push(modelName);
    }
    return display_Name.join(" ");
}
export const formulaDynamic = (productName, productType, brand, descriptionFeatures, modelName, substituteList) =>{
    let display_Name = [];

    if(!isEmpty(productName)){
        display_Name.push(productName);
    }
    else{
        let indexsubstitute = substituteList.findIndex(x => x.type === productType);
        let value = (indexsubstitute > -1) ? substituteList[indexsubstitute].substitute.replaceAll("-"," ") : "";
        if (value !== ""){
            display_Name.push( value );
        }
        
    }
    if(!isEmpty(brand)){
        display_Name.push(brand);
    }
    if(!isEmpty(modelName)){
        display_Name.push(modelName);
    }
    if(!isEmpty(descriptionFeatures)){
        display_Name.push(descriptionFeatures);
    }
    
    return display_Name.join(" ");
}
export const formulaAmazon = (brand, modelName, productType, descriptionFeatures, descriptionExtendedFeatures, Color, substituteList) =>{
    let display_Name = [];
    if(!isEmpty(brand)){
        display_Name.push(brand);
    }
    if(!isEmpty(modelName)){
        display_Name.push(modelName);
    }
    let indexsubstitute = substituteList.findIndex(x => x.type === productType);
    let value = (indexsubstitute > -1) ? substituteList[indexsubstitute].substitute.replaceAll("-"," ") : "";
    if (value !== ""){
        display_Name.push( value );
    }
    if(!isEmpty(descriptionFeatures)){
        display_Name.push(descriptionFeatures);
    }
    if(!isEmpty(descriptionExtendedFeatures)){
        display_Name.push(descriptionExtendedFeatures);
    }
    if(!isEmpty(Color)){
        display_Name.push("- " + Color);
    }
    return display_Name.join(" ");
}
export const formulaPackageContent = (productType, brand, modelName, substituteList) =>{
    let display_Name = ["1"];

    let indexsubstitute = substituteList.findIndex(x => x.type === productType);
    let value = (indexsubstitute > -1) ? substituteList[indexsubstitute].substitute.replaceAll("-"," ") : "";
    if (value !== ""){
        display_Name.push( value );
    }

    if(!isEmpty(brand)){
        display_Name.push(brand);
    }

    if(!isEmpty(modelName)){
        display_Name.push(modelName);
    }
    return display_Name.join(" ");
}
export const returnDefault0 = (value) =>{
    return isEmpty(value) ? 0 : value;
}
export const putFetch = async ( url, json ) =>{
    const response = await fetch( mainURL + url, { method: "PUT", mode: "cors", headers: { "Content-Type": "application/json" }, body: json });
    return await response.json();
}
export const putFetchToken = async ( url, json ) =>{
    const response = await fetch( mainURL + url, { method: "PUT", mode: "cors", headers: { "Content-Type": "application/json", "X-Viastara-Token": getCookie() }, body: json });
    return await response.json();
}
export const deleteFetch = async ( url, json ) =>{
    const response = await fetch( mainURL + url, { method: "DELETE", headers: { "Content-Type": "application/json"}, body: json});
    return await response.json();
}
// create / edit product
export const msrp_off = (cost, msrp) => {
    cost = (cost === null || cost === "") ? 0 : cost;
    msrp = (msrp === null || msrp === "") ? 0 : msrp;
    if(cost === 0 || msrp === 0){
        return "";
    }
    return ((1 - (cost / msrp)) * 100).toFixed(2).toString() + "%"
}
export const limit_100 = (value) => {
    const parsed = parseInt(value)
    return Math.min(Math.max(parsed, 0), 100)
}
export const Numbers = (value) => {
    if(typeof value === 'number'){

    }
}