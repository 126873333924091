// validators working Task
export const regexValidator = (value, callback)=>{
    setTimeout(() => {
        let newValue = ( value === undefined || value === null ) ? "" : value;
        // const regex = new RegExp("((^([A-Z][a-z]*)|(i[A-Z][a-z]*))( i[A-Z][a-z]*|, i[A-Z][a-z]*|. i[A-Z][a-z]*| [a-z]*|. [A-Z][a-z]*|, [a-z]*)*)");
        const regex = new RegExp("^(i?[A-Z0-9]+[a-z]* ?(?:[A-Za-z0-9]+ ?)?(?: \\([0-9a-zA-Z ]+\\)?)?(?:(?: y )|(?:[,\\.]) )?)+"); // sergio regex
        callback(newValue === "" || (regex.test(newValue) && !newValue.includes("  ")));
    });
}
export const countValidator2 = (value, callback) =>{
    setTimeout(() => {
        callback(value.toString().length <= 2 || isNaN(value));
    });
}
export const countValidator4 = (value, callback) =>{
    setTimeout(() => {
        callback(value.toString().length <= 4 || isNaN(value));
    });
}
export const countValidator5 = (value, callback) =>{
    setTimeout(() => {
        callback(value.toString().length <= 5 || isNaN(value));
    });
}
export const countValidator6 = (value, callback) =>{
    setTimeout(() => {
        callback(value.toString().length <= 6);
    });
}
export const countValidator12 = (value, callback) =>{
    setTimeout(() => {
        callback(value.toString().length <= 12);
    });
}
export const countValidator32 = (value, callback) =>{
    setTimeout(() => {
        callback(value.toString().length <= 32);
    });
}
export const countValidator60_num = (value, callback) =>{
    setTimeout(() => {
        callback(!(value > 60));
    });        
}
export const countValidator200_num = (value, callback) =>{
    setTimeout(() => {
        callback(!(value > 200));
    });        
}
// validators create / edit product
export const countValidator14 = (value, callback) =>{
    setTimeout(() => {
        value = (value === null) ? "" : value;
        callback(value.toString().length <= 14);        
    });
}
export const countValidator20 = (value, callback) =>{
    setTimeout(() => {
        value = (value === null) ? "" : value;
        callback(value.toString().length <= 20);
    });
}
export const countValidator64 = (value, callback) =>{
    setTimeout(() => {
        value = (value === null) ? "" : value;
        callback(value.toString().length <= 64);
    });
}
export const countValidator100 = (value, callback) =>{
    setTimeout(() => {
        value = (value === null) ? "" : value;
        callback(value.toString().length <= 100);
    });
}
export const countValidator128 = (value, callback) =>{
    setTimeout(() => {
        value = (value === null) ? "" : value;
        callback(value.toString().length <= 128);
    });
}
export const listValidator = (value, callback) =>{
    setTimeout(() => {
        callback(true);
    });
}