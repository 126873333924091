import React, { useEffect, useState } from "react";
import SideMenu from "../Components/sideMenu";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ImageGallery from 'react-image-gallery';
import {formulaWalmart, formulaDynamic, formulaAmazon, getAxios, tempImgURL, productImgURL} from "../Components/commonFunctions"

const UpdateProductTypes=(props)=>{

    const [getData, setData] = useState({});
    // eslint-disable-next-line
    const [getSubstitutes, setSubstitutes] = useState([]);
    const [getOriginName, setOriginName] = useState("");
    const [getImages, setImages] = useState([]);
    const [getMediaFiles, setMediaFiles] = useState([]);

    const [getMercadoLibre, setMercadoLibre] = useState("");
    const [getWalmart, setWalmart] = useState("");
    const [getAmazon, setAmazon] = useState("");
    const [getKeywords, setKeywords] = useState("");
    const [getfeatures, setFeatures] = useState([]);

    useEffect(()=>{
        getInformation();
        // eslint-disable-next-line
    },[]);

    const getInformation = async() => {
        setOriginName(localStorage.getItem("originName"));
        let data = JSON.parse(localStorage.getItem("selectedItem"));
        let features = [];
        for (let index = 1; index < 11; index++) {
            let f = data["feature_" + index];
            if(f !== ""){
                features.push(f);
            }            
        }
        setFeatures(features);
        // obtener imagenes.
        let tid = (localStorage.getItem("originName") === "Asignacion de tareas") ? "" : localStorage.getItem("tid");
        let urlToGetImages = "task/getTmpImages?tid=" + tid + "&upc=" + data["upc"];
        let substitutes = [];
		getAxios(urlToGetImages).then(response => {
            let images = [];
            let urlImage = (localStorage.getItem("originName") === "Asignacion de tareas") ? productImgURL : tempImgURL;
			for (let index = 0; index < response.length; index++) {
                images.push({original: urlImage+response[index].file, thumbnail: urlImage+response[index].file});
			}
            setImages(images);
		});
        // obtener la lista de sustituciones.
        await getAxios("product/getTypes").then(response=>{
            setSubstitutes(response);
            substitutes = response;
		});
        // obtener los archivos multimedia.
        await getAxios("task/media?upc=" + data["upc"]).then(response=>{
            let mediaFiles = [];
			for (let index = 0; index < response.media.length; index++) {
                mediaFiles.push(<div key={index}><a href={response.media[index].path} target="_blank" rel="noreferrer">{response.media[index].file}</a></div>);
			}
            setMediaFiles(mediaFiles);
		});
        // aplicacion de formulas y guardado de datos.
        setData(data);
        setKeywords(data["keywords"] ? data["keywords"].replaceAll(";",", ") : "");
        setMercadoLibre(data["ml_manual_title"] || formulaDynamic(data["product_name"], data["product_type"], data["brand"], data["description_features"], data["model"], substitutes));
        setWalmart(formulaWalmart(data["product_name"], data["product_type"], data["brand"], data["description_features"], data["model"], substitutes));
        setAmazon(formulaAmazon(data["brand"], data["model"], data["product_type"], data["description_features"], data["description_extended_features"], data["color"], substitutes))
    }
    const insertFeatures = (index, data) => {
        return(
            <>
                <h5>Feature {index}:</h5><p>{data}</p>
            </>
        );
    }
    return(
        <>
        <SideMenu title="Actualizacion de product types"/>
        <Container className="marginTopContainer">
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active href="/taskManager">Administrador de tareas</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">{getOriginName}</Breadcrumb.Item>
                        <Breadcrumb.Item active href="#">Preview del producto</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6} lg={6}>
                    <Row>
                        <ImageGallery thumbnailPosition="left" items={getImages} showFullscreenButton={false} showPlayButton={false}/>
                    </Row>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Row>
                        <Col><h5>Item Number: </h5><p>{getData["itemNumber"]}</p></Col>
                        <Col><h5>UPC: </h5><p>{getData["upc"]}</p></Col>
                    </Row>
                    <Row>
                        <Col><h5>Department: </h5><p>{getData["dept"]}</p></Col>
                        <Col><h5>Subclass: </h5><p>{getData["subclass"]}</p></Col>
                        <Col><h5>Class: </h5><p>{getData["class"]}</p></Col>
                    </Row>
                    <Row>
                        <Col><h5>Brand: </h5><p>{getData["brand"]}</p></Col>
                        <Col><h5>Color Title: </h5><p>{getData["color"]}</p></Col>
                        <Col><h5>Color Brand: </h5><p>{getData["color_brand"]}</p></Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><h5>Item Description: </h5><p>{getData["description"]}</p></Col>
                    </Row>
                    <Row>
                        <Col><h5>Título en español: </h5><p>{getData["description_es"]}</p></Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><h5>Web Link: </h5><a href={getData["url"]} target="_blank" rel="noreferrer">{getData["url"]}</a></Col>
                    </Row>
                    <Row>
                        <Col><h5>Video Link: </h5><a href={getData["video_url"]} target="_blank" rel="noreferrer">{getData["video_url"]}</a></Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><h5>Media files: </h5><p>{getMediaFiles}</p></Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5>Descripción de producto: </h5><p>{getData["ext_es"]}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    {getfeatures.map((data, index)=>{
                        return insertFeatures(index + 1, data);
                    })}
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <Row>
                        <Col><h5>Model name: </h5><p>{getData["model"]}</p></Col>
                    </Row>
                    <Row>
                        <Col><h5>Título Mercado Libre: </h5><p>{getMercadoLibre}</p></Col>
                    </Row>
                    <Row>
                        <Col><h5>Título Walmart: </h5><p>{getWalmart}</p></Col>
                    </Row>
                    <Row>
                        <Col><h5>Título Amazon: </h5><p>{getAmazon}</p></Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col><h5>Product Type: </h5><p>{getData["product_type"]}</p></Col>
                    </Row>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col><h5>Keywords: </h5><p>{getKeywords}</p></Col>
            </Row>
            <hr></hr>

            <Row>
                <Col><h5>Device Compatibility: </h5><p>{getData["device_compatibility"]}</p></Col>
                <Col><h5>Lithium Battery: </h5><p>{getData["lithium_battery"]}</p></Col>
                <Col><h5>Country of Origin: </h5><p>{getData["country_origin"]}</p></Col>
            </Row>
            <Row>
                <Col><h5>OS Compatibility: </h5><p>{getData["os_compatibility"]}</p></Col>
                <Col><h5>Wireless Product: </h5><p>{getData["wireless"]}</p></Col>
                <Col><h5>Power Specs: </h5><p>{getData["power_specs"]}</p></Col>
            </Row>
            <Row>
                <Col><h5>Material Composition: </h5><p>{getData["material_composition"]}</p></Col>
                <Col><h5>Warranty Length: </h5><p>{getData["warranty_length"]}</p></Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col><h5>Lifetime Warranty: </h5><p>{getData["lifetime_warranty"]}</p></Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col></Col>
                <Col><h5>Uno (1) refiere a "SI"</h5></Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h5>Package Content: </h5><p>{getData["package_content_ex"]}</p>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h5>Product dimensions ( length x width x height ): </h5>
                            <p>{getData["length"] + " x " + getData["width"] + " " + getData["height"] + " " + getData["dimensions_unit"]}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>Product Weight: </h5>
                            <p>{getData["weight"] + " " + getData["weight_unit"]}</p>
                        </Col>
                    </Row>                    
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <h5>Package dimensions ( length x width x height ): </h5>
                            <p>{getData["pkg_length"] + " x " + getData["pkg_width"] + " " + getData["pkg_height"] + " " + getData["dimensions_unit"]}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>Package Weight: </h5>
                            <p>{getData["pkg_weight"] + " " + getData["weight_unit"]}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default UpdateProductTypes;